import { useLocation, Link as InternalLink } from "react-router-dom";
import { Link } from "react-scroll";
import styled from "styled-components";

const Fade = require("react-reveal/Fade");

interface NavItemType {
  label: string;
  component: string;
  page?: boolean;
}

const navItems: NavItemType[] = [
  // {
  //   label: "Home",
  //   component: "header-scroll",
  // },
  {
    label: "The Story",
    component: "story-scroll",
  },
  // {
  //   label: "Gallery",
  //   component: "gallery-scroll",
  // },
  {
    label: "Roadmap",
    component: "roadmap-scroll",
  },
  {
    label: "Artist",
    component: "artist-scroll",
  },
  {
    label: "Team",
    component: "team-scroll",
  },
  {
    label: "Gallery",
    component: "gallery",
    page: true,
  },
  {
    label: "License",
    component: "license",
    page: true,
  },
  {
    label: "Play Cyber Run",
    component: "cyber-run",
    page: true,
  },
];

const StyledNavItems = styled.div`
  display: flex;
  a:not(:last-child) {
    margin-right: 2rem;
  }
  @media only screen and (max-width: 750px) {
    flex-direction: column;
    align-items: center;
    gap: 15px;
    a,
    div {
      margin: 0 !important;
    }
  }
`;

interface NavItemProps {
  activeTab: boolean;
}

const NavItem = styled.div`
  text-transform: uppercase;
  color: ${(props: NavItemProps) =>
    props.activeTab ? "var(--primary)" : "var(--sub)"};
  cursor: pointer;
  font-family: Impact, "Anton", sans-serif;
  font-size: 2rem;
  @media only screen and (max-width: 750px) {
    margin-bottom: 3rem;
  }
  @media only screen and (max-width: 1307px) {
    font-size: 1.9rem;
    margin: 0 1rem;
  }

  transition: color 0.3s;
  :hover {
    color: ${(props: NavItemProps) =>
      props.activeTab ? "var(--primary)" : "var(--main)"};
  }
`;

const NavItems = () => {
  const location = useLocation();

  const isHome = location.pathname === "/";

  return (
    <Fade bottom>
      <StyledNavItems>
        {isHome &&
          navItems.map((navItem: NavItemType, index: number) => (
            <>
              {navItem.page ? (
                <InternalLink to={navItem.component} key={index}>
                  <NavItem activeTab={false}>{navItem.label}</NavItem>
                </InternalLink>
              ) : (
                <Link
                  key={navItem.component}
                  to={navItem.component}
                  spy={true}
                  smooth={true}
                  offset={-30}
                  duration={2000}
                >
                  <NavItem activeTab={index === 0}>{navItem.label}</NavItem>
                </Link>
              )}
            </>
          ))}
        {!isHome &&
          navItems.map((navItem: NavItemType, index: number) => (
            <InternalLink
              to={navItem.page ? `/${navItem.component}` : "/"}
              key={index}
            >
              <NavItem
                activeTab={location.pathname === `/${navItem.component}`}
              >
                {navItem.label}
              </NavItem>
            </InternalLink>
          ))}
      </StyledNavItems>
    </Fade>
  );
};

export default NavItems;
