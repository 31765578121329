import styled from "styled-components";
import useGlobals from "../../app/hooks/use-globals";
import { Header2 } from "../../styles/Headers";

const Fade = require("react-reveal/Fade");

const StyledStory = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 12rem 0;
  max-width: 110rem;

  @media only screen and (max-width: 600px) {
    margin: 5rem 0;
    padding: 0 3rem;
  }

  @media only screen and (min-width: 601px) and (max-width: 1200px) {
    margin: 5rem 0;
    padding: 0 3rem;
  }
`;

const SubHeader = styled.p`
  font-size: 2.3rem;
  font-weight: 500;
  max-width: 100rem;
  text-align: center;
  margin-bottom: 3rem;

  @media only screen and (max-width: 600px) {
    font-size: 1.6rem;
    margin-bottom: 2rem;
  }

  @media only screen and (min-width: 601px) and (max-width: 1200px) {
    font-size: 1.8rem;
  }
`;

const Story = () => {
  const globals = useGlobals();

  return (
    <StyledStory id="story-scroll">
      <Fade bottom>
        <Header2>the story</Header2>
      </Fade>
      <Fade bottom>
        <SubHeader>
          Cerise is a woman of many faces. Yet by spy networks and online
          sleuths, she is known only as the Killer GF assassin— the uncatchable
          killer who has no true face. The secret to her success lies in one
          supernatural gift. Wielding the Mirror of Narcissus, she holds at her
          fingertips the ability to reform her appearance into any disguises and
          powers using this dark artifact. This mirror, which consumes both the
          life essences and appearance of her kills, was a gift from the Crimson
          God himself. A heirloom of sorts for his favorite dearly beloved
          killer to better serve him by carrying out the critical assassinations
          that bring down societies, religions, and supernatural entities. Gods,
          even with their gifts, can be cruel with jests that transcend across
          the multiverse…
        </SubHeader>
        <SubHeader>
          {`Knowing little of the trick inlaid within the Mirror, Cerise used it once more. But this one, being the ${globals.SIZE.toLocaleString()}th time, triggered a breakage of dimensions within the mirror, pulling forth ${globals.SIZE.toLocaleString()} versions of Cerise herself out into the same dimension, wearing what appears to be every disguise she has ever worn in the past for her kills.`}
        </SubHeader>
        <SubHeader>
          {`Now that is a lot of deadly assassins in one room. But get this, each being sentient and believing that she is the real Cerise, they agree the only way to determine the one true Cerise would be to participate in a game. A deadly set of challenges within the metaverse where by playing and winning hearts, the Cerises compete against each other using daring and cunning tactics with only one coming out of it alive by the end. They say the biggest enemy standing in your way is yourself, but did they mean all ${globals.SIZE.toLocaleString()}?`}
        </SubHeader>
      </Fade>
    </StyledStory>
  );
};

export default Story;
