import { Fragment, useEffect, useState, useCallback } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import GlobalStyles from "../../styles/GlobalStyles";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import GalleryCard from "../../components/GalleryCard/GalleryCard";
import SkeletonCards from "../../components/GalleryCard/SkeletonCards";
import GalleryFilter from "../../components/GalleryFilter";
import styled from "styled-components";
import Modal from "../../components/GalleryCard/Modal";
import FilterSidebar from "../../components/FilterSidebar";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  iconKGF,
  iconHat,
  iconBackground,
  iconClothes,
  iconEyes,
  iconEyeWear,
  iconHair,
  iconMouth,
  iconSkin,
  iconWeapon,
} from "../../assets";
import useImages from "../../hooks/useImages";
const Fade = require("react-reveal/Fade");

interface GalleryFilterProps {
  active: boolean;
}

interface FilterGalleryType {
  id: string;
  name: string;
  image: string;
  checkTitle: Array<any>;
}

interface FilterSelections {
  [category: string]: (number | string)[];
}

const StyledHomePage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  min-height: 100vh;
  background-color: var(--bg);
  overflow-x: hidden;
`;
const Wrapper = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
  .react-reveal:last-child {
    flex: 10;
  }
  @media only screen and (max-width: 767px) {
    padding: 20px;
  }
  @media only screen and (min-width: 991px) {
    padding: 20px 40px 0 30px;
  }
`;
const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
  gap: 2.5rem;
  width: 100%;

  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(3, minmax(210px, 1fr));
  }

  @media only screen and (min-width: 1440px) {
    grid-template-columns: repeat(4, minmax(210px, 1fr));
  }

  @media only screen and (min-width: 1836px) {
    grid-template-columns: repeat(6, minmax(210px, 1fr));
  }

  @media only screen and (min-width: 2160px) {
    grid-template-columns: repeat(8, minmax(210px, 1fr));
  }
`;
const GalleryWrapper = styled.div`
  flex: 10;
  padding-left: ${(props: GalleryFilterProps) =>
    props.active ? "2.5rem" : "6.5rem"};
  @media only screen and (max-width: 1280px) {
    padding-left: ${(props: GalleryFilterProps) =>
      props.active ? "2.5rem" : "4rem"};
  }
  @media screen and (max-width: 991px) {
    padding-left: 0;
  }
  article {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media only screen and (max-width: 991px) {
      margin-bottom: 1.2rem;
    }
  }
`;
const Icon = styled.div`
  width: 35px;
  height: 35px;
  background-color: var(--primary);
  cursor: pointer;
  display: block;
  border-radius: 5px;
  display: grid;
  place-items: center;
  svg {
    fill: var(--white);
    width: 20px;
    height: 20px;
  }
  @media only screen and (min-width: 991px) {
    display: none;
  }
`;
const FilterChip = styled.div`
  position: relative;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px 12px 8px 12px;
  margin: 0 12px 1.25rem 0;
  font-weight: 600;
  font-size: 1.25em;
`;
const FilterChipX = styled(FilterChip)`
  padding-right: 36px;

  &::after {
    content: "\\00D7";
    font-weight: 500;
    font-size: 2.5em;
    line-height: 1em;
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translate(-50%, -50%);
  }
`;
const TotalCount = styled.div`
  position: relative;
  padding: 8px 12px 8px 0px;
  margin: 0 12px 1.25rem 0;
  font-weight: 600;
  font-size: 1.25em;
`;

const ModalLink = styled.div``;
const Gallery = () => {
  const [modal, setModal] = useState(false);
  const [modalItem, setModalItem] = useState(null);
  const [sideFilter, setSideFilter] = useState(false);

  const [filters, setFilters] = useState<{ [key: string]: never[] }>({});
  const [filterSide, setFilterSide] = useState(false);

  // New state to hold the active filters
  const [activeFilters, setActiveFilters] = useState<FilterSelections | null>(
    null
  );

  const { images, imageTotalCount, fetchMore, hasMore, error, resetError } =
    useImages(activeFilters);

  const handleSelectionChange = (selectedItemsFromChild: FilterSelections) => {
    setActiveFilters(() => selectedItemsFromChild);
  };

  const resetFilters = () => {
    setActiveFilters({});
    resetError("");
  };

  const removeFilter = useCallback(
    (category: string, filter: string | number) => {
      if (error) resetError("");
      setActiveFilters((oldFilters) => {
        if (oldFilters) {
          const newFilters = { ...oldFilters };
          newFilters[category] = newFilters[category].filter(
            (f: string | number) => f !== filter
          );
          return newFilters;
        }
        return oldFilters;
      });
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const modalOpen = (item: any) => {
    setModal((prev) => !prev);
    setModalItem(item);
  };

  const filterSideHandle = () => {
    setFilterSide((prev) => !prev);
  };

  const fetchFilters = async () => {
    let tempFilters: { [key: string]: any } = {};

    const collectionRef = collection(db, "filters");
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach((doc) => {
      // Here, doc.data() will return an object with a property 'values' which is an array
      // We are setting filters[<category>] to this array
      tempFilters[doc.id.replace(/\s+/g, "").toLowerCase()] = doc.data().values;
    });
    setFilters(() => tempFilters);
  };

  useEffect(() => {
    fetchFilters();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const FilterMembers: FilterGalleryType[] = [
    {
      id: "1",
      name: "Background",
      checkTitle: filters.background,
      image: iconBackground,
    },
    {
      id: "2",
      name: "Clothes",
      checkTitle: filters.clothes,
      image: iconClothes,
    },
    {
      id: "3",
      name: "Eyes",
      checkTitle: filters.eyes,
      image: iconEyes,
    },
    {
      id: "4",
      name: "Eyewear",
      checkTitle: filters.eyewear,
      image: iconEyeWear,
    },
    {
      id: "5",
      name: "Hair",
      checkTitle: filters.hair,
      image: iconHair,
    },
    {
      id: "6",
      name: "Hat",
      checkTitle: filters.hat,
      image: iconHat,
    },
    {
      id: "7",
      name: "KILLER GF",
      checkTitle: filters.killergf,
      image: iconKGF,
    },
    {
      id: "8",
      name: "Mouth",
      checkTitle: filters.mouth,
      image: iconMouth,
    },
    {
      id: "9",
      name: "Skin",
      checkTitle: filters.skin,
      image: iconSkin,
    },
    {
      id: "10",
      name: "Weapon",
      checkTitle: filters.weapon,
      image: iconWeapon,
    },
  ];

  console.log(imageTotalCount);

  return (
    <Fragment>
      <StyledHomePage>
        <GlobalStyles />
        <Header />
        <Wrapper>
          <GalleryFilter
            active={sideFilter}
            setActive={setSideFilter}
            FilterMembers={FilterMembers}
            handleSelectionChange={handleSelectionChange}
            selectedItems={activeFilters}
            setSelectedItems={setActiveFilters}
          />
          <FilterSidebar
            filterSideHandle={filterSideHandle}
            active={filterSide}
            FilterMembers={FilterMembers}
            handleSelectionChange={handleSelectionChange}
            selectedItems={activeFilters}
            setSelectedItems={setActiveFilters}
          />
          <Fade bottom>
            <GalleryWrapper active={sideFilter}>
              <article>
                <Icon onClick={filterSideHandle}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    className="w-5 h-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Icon>
              </article>
              {error && (
                <p>
                  To many filters... broaden your search or{" "}
                  <span style={{ color: "blue" }} onClick={resetFilters}>
                    reset all filters
                  </span>
                </p>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  marginBottom: "1.25rem",
                }}
              >
                {imageTotalCount > 0 && (
                  <TotalCount>
                    {imageTotalCount} filtered result
                    {imageTotalCount === 1 ? "" : "s"}
                  </TotalCount>
                )}
                {activeFilters &&
                  Object.keys(activeFilters).map((category) =>
                    activeFilters[category].map((filter) => (
                      <FilterChipX
                        key={filter}
                        onClick={() => removeFilter(category, filter)}
                      >
                        {filter}
                      </FilterChipX>
                    ))
                  )}
                {activeFilters != null ? (
                  Object.values(activeFilters).reduce(
                    (accumulator, currentArray) =>
                      accumulator + currentArray.length,
                    0
                  ) !== 0 ? (
                    <FilterChip onClick={() => resetFilters()}>
                      Clear All
                    </FilterChip>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
              {images && (
                <InfiniteScroll
                  dataLength={images.length}
                  next={fetchMore}
                  hasMore={hasMore}
                  loader={
                    <CardWrapper style={{ marginTop: "15px" }}>
                      <SkeletonCards count={24} />
                    </CardWrapper>
                  }
                  endMessage={
                    <p style={{ marginTop: "2.5rem" }}>
                      End of filter results ♥
                    </p>
                  }
                >
                  <CardWrapper>
                    {images.map((item: any) => {
                      return (
                        <ModalLink
                          key={item.id}
                          onClick={() => modalOpen(item)}
                        >
                          <GalleryCard number={item.id} />
                        </ModalLink>
                      );
                    })}
                  </CardWrapper>
                </InfiniteScroll>
              )}
            </GalleryWrapper>
          </Fade>
        </Wrapper>
        <Footer />
      </StyledHomePage>

      {modal && <Modal modalOpen={modalOpen} kgf={modalItem} />}
    </Fragment>
  );
};

export default Gallery;
