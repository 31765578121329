import styled from "styled-components";

export const Header1 = styled.h1`
  position: relative;
  font-family: Impact, "Anton", sans-serif;
  color: var(--primary);
  text-transform: uppercase;
  margin-bottom: 3rem;
  margin: auto;
  margin-top: 10rem;

  font-size: 7rem;
  line-height: 8.5rem;
  margin-bottom: 3rem;
  @media only screen and (max-width: 600px) {
    font-size: 6rem;
    line-height: 7.5rem;
    margin-bottom: 1.5rem;
  }
`;

export const Header2 = styled.h2`
  position: relative;
  font-family: Impact, "Anton", sans-serif;
  line-height: 8.5rem;
  color: var(--primary);
  text-transform: uppercase;
  white-space: nowrap;

  font-size: 5.5rem;
  margin-bottom: 3rem;
  @media only screen and (max-width: 600px) {
    font-size: 4.3rem;
    margin-bottom: 1.5rem;
  }
`;
