import styled from "styled-components";
import { Header1 } from "../styles/Headers";
import Footer from "./Footer";

const theCrew = [3, 4, 5, 6];

const StyledLicense = styled.div`
  padding: 0 5rem;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--bg-02);

  @media (max-width: 720px) {
    padding: 2rem;
    padding-top: 7rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 150em;
`;

const SmallHeader = styled.h2`
  font-weight: 700;
  color: var(--primary);
  text-transform: uppercase;
  margin-bottom: 0.3rem;
  margin-top: 3rem;

  font-size: 2.4rem;
  @media only screen and (max-width: 600px) {
    font-size: 1.6rem;
  }
`;

const SubHeader = styled.div`
  font-weight: 500;
  color: var(--main);

  font-size: 2rem;
  margin-top: 0.6rem;
  @media only screen and (max-width: 600px) {
    font-size: 1.5rem;
    margin-top: 0.4rem;
  }
`;

const Link = styled.a`
  font-weight: 500;
  color: var(--main);
  text-decoration: underline;

  font-size: 2rem;
  margin-top: 0.6rem;
  @media only screen and (max-width: 600px) {
    font-size: 1.5rem;
    margin-top: 0.4rem;
  }
`;

const Kgfs = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 1rem;
  margin-top: 5rem;
  margin-bottom: 10rem;
`;

const KgfContainer = styled.a`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  transform: scale(1);
  transition: 0.3s transform;
  :hover {
    transform: scale(1.05);
  }
`;

const KgfImage = styled.img`
  width: 100%;
`;

const KgfText = styled.div`
  font-weight: 500;
  font-size: 2rem;
`;

const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  margin-left: 3rem;
`;

const Li = styled.li`
  font-weight: 500;
  color: var(--main);

  font-size: 2rem;
  margin-top: 0.6rem;
  margin-left: 3rem;
  @media only screen and (max-width: 600px) {
    font-size: 1.5rem;
    margin-top: 0.4rem;
    margin-left: 2rem;
  }
`;

const License = () => {
  return (
    <StyledLicense>
      <Content>
        <Header1>Killer GF Art License</Header1>
        <SmallHeader>Based on the uwu Art License!</SmallHeader>
        <SubHeader>
          Killer GF uses the{" "}
          <Link
            href="https://mirror.xyz/uwucrew.eth/DwrrmMYgsUT0mXsMEvbWBoLtnKcW0TRKOpwUWjDbZXg"
            target="_blank"
          >
            uwu Art License
          </Link>
          ! The uwu Art License is focused on holder representation and
          respecting the holders of the NFTs, while also including a set of NFTs
          that are licensed differently as CC0 in order to allow community art
          to flourish.
        </SubHeader>
        <SmallHeader>Keeping it uwu</SmallHeader>
        <SubHeader>In uwulabs spirit, “keeping it uwu” means:</SubHeader>
        <Ul>
          <Li>
            Giving proper credit and respecting the artist(s) behind the art
          </Li>
          <Li>
            Respecting how the owner of an NFT would want their NFT to be
            represented
          </Li>
          <Li>
            Welcoming derivatives, but requiring NFT ownership or clear
            permission from the NFT owner for commercial use
          </Li>
        </Ul>
        <SubHeader>
          With that in mind, we are happy to support these values through our
          own art license.
        </SubHeader>
        <SmallHeader>Holders</SmallHeader>
        <Link href="http://creativecommons.org/licenses/by/4.0" target="_blank">
          CC BY 4.0
        </Link>
        <SubHeader>For the NFT(s) you own you’re free to:</SubHeader>
        <Ul>
          <Li>Copy or share in any medium</Li>
          <Li>
            Make derivatives, remix, or transform in any medium, even
            commercially
          </Li>
          <Li>As long as you give proper credit to Killer GF</Li>
        </Ul>
        <SmallHeader>Non-Holders</SmallHeader>
        <Link
          href="http://creativecommons.org/licenses/by-nc/4.0"
          target="_blank"
        >
          CC BY-NC 4.0
        </Link>
        <SubHeader>For any NFT in the collection you’re free to:</SubHeader>
        <Ul>
          <Li>Copy or share in any medium</Li>
          <Li>
            Make derivatives, remix, or transform in any medium, but NOT
            commercially
          </Li>
          <Li>As long as you give proper credit to Killer GF</Li>
        </Ul>
        <SmallHeader>Killer GF Team</SmallHeader>
        <SubHeader>
          The Killer GF team reserves rights to use all Killer GF collection NFT
          art and all derivative art for promotional use.
        </SubHeader>
        <SmallHeader>Usage with Permission</SmallHeader>
        <SubHeader>
          The owner of the NFT not only owns the art, but also the character of
          the NFT as well — meaning that they have the ability to grant
          permission for others to sell derivative art that includes their
          character for as long as they own the original NFT.
        </SubHeader>
        <SubHeader>
          Keep in mind that this permission only persists for the owner of the
          NFT, so if the original NFT is sold after permission was given,
          commercial usage permission of the character must be requested again
          from the new owner.
        </SubHeader>
        <Header1>THE KGF ARMY</Header1>
        <SmallHeader>Community collection</SmallHeader>
        <SubHeader>
          Killer GF has 4 mascot characters that are the start of the Killer GF
          community collection a.k.a “The KGF Army"! The community collection
          refers to KGFs licensed as CC0, which means they are public domain,
          and free to use by anyone for any purpose! Once a Killer GF NFT is
          added to the community collection, it will never be removed. More KGFs
          may join The KGF Army in the future!
        </SubHeader>
        <SmallHeader>Community license</SmallHeader>
        <Link
          href="https://creativecommons.org/share-your-work/public-domain/cc0"
          target="_blank"
        >
          CC0
        </Link>
        <SubHeader>For all NFTs in the community collection:</SubHeader>
        <Ul>
          <Li>Anyone is free to copy or share in any medium</Li>
          <Li>
            Anyone is free to make derivatives, remix, or transform in any
            medium, or use for any purpose, even commercially
          </Li>
        </Ul>
        <Kgfs>
          {theCrew.map((kgf: number) => (
            <KgfContainer
              href={`https://opensea.io/assets/ethereum/0x6be69b2a9b153737887cfcdca7781ed1511c7e36/${kgf}`}
              target="_blank"
              key={kgf}
            >
              <KgfImage
                src={`https://uwulabs.mypinata.cloud/ipfs/QmTifGUmic9roD1BuH62QmzZTVWapUSD5m5dwcgjuaHGHQ/${kgf}.png`}
              />
              <KgfText>{`Killer GF: #${kgf}`}</KgfText>
            </KgfContainer>
          ))}
        </Kgfs>
        <Footer />
      </Content>
    </StyledLicense>
  );
};

export default License;
