import { useEthers } from "@usedapp/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { selectError } from "../state/errorSlice";

const Fade = require("react-reveal/Fade");

interface ButtonProps {
  primary?: boolean;
}

const StyledButton = styled.button`
  padding: 1rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: 25rem;
  height: 5.3rem;
  font-size: ${(props: ButtonProps) => (props.primary ? "1.9rem" : "1.6rem")};
  font-weight: 700;
  background: var(--primary);
  border-radius: 2rem;
  color: var(--bg);

  :disabled {
    cursor: auto;
    background-color: var(--sub);
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

interface Props {
  text: string;
  click: () => void;
  disabled?: boolean;
  loading?: boolean;
  primary?: boolean;
}

const Button = ({ text, click, disabled, loading, primary }: Props) => {
  const { account, activateBrowserWallet } = useEthers();
  const error = useSelector(selectError);
  const [pending, setPending] = useState(false);

  const isWeb3 = loading !== undefined;

  useEffect(() => {
    if (error || loading) setPending(false);
  }, [error, loading]);

  return (
    <Fade bottom>
      <StyledButton
        primary={primary}
        onClick={() => {
          if (loading || disabled || pending) return;
          if (isWeb3) setPending(true);
          if (isWeb3 && !account) activateBrowserWallet();
          else click();
        }}
        disabled={disabled || loading || pending}
      >
        {isWeb3 && !account ? "Connect Wallet" : loading ? "Loading..." : text}
      </StyledButton>
    </Fade>
  );
};

export default Button;
