import { useRef } from "react";
import styled from "styled-components";
import useWindowPosition from "../../app/hooks/use-window-position";

import image01 from "../../assets/hero-image/01.jpg";
import image02 from "../../assets/hero-image/02.png";
import image03 from "../../assets/hero-image/03.png";
import image04 from "../../assets/hero-image/04.png";

const StyledHeroImage = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  max-width: 130rem;
  margin-bottom: 12rem;

  @media only screen and (max-width: 600px) {
    margin-bottom: 5rem;
    margin-top: 2rem;
  }

  @media only screen and (min-width: 601px) and (max-width: 1200px) {
    margin-bottom: 5rem;
    margin-top: 2rem;
  }
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const Overlay = styled.img`
  position: relative;
  width: 100%;
`;

const GradientLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 10%;
  background: linear-gradient(to right, var(--bg), transparent);

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const GradientRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 10%;
  background: linear-gradient(to left, var(--bg), transparent);

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const GradientTop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20%;
  background: linear-gradient(to bottom, var(--bg), transparent);

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const GradientBottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20%;
  background: linear-gradient(to top, var(--bg), transparent);

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const HeroImage = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line
  const windowPosition = useWindowPosition();

  const height = containerRef.current
    ? containerRef.current.getBoundingClientRect().height
    : 0;
  const getTransform = () => {
    const pageHeight = window.innerHeight;
    if (!containerRef.current) return 0;
    const top = containerRef.current.getBoundingClientRect().top;
    const end = pageHeight + height;
    if (top > pageHeight) return 0;
    const offset = pageHeight - top;
    if (offset > end) return 1;
    return offset / end;
  };
  const transform = getTransform();

  return (
    <StyledHeroImage ref={containerRef}>
      <Image
        src={image01}
        alt="promotional image"
        style={{
          transform: `translateY(calc((${height}px - 100%) * ${transform}))`,
        }}
      />
      <GradientLeft />
      <GradientRight />
      <Image
        src={image02}
        alt="promotional image"
        style={{
          transform: `translateY(calc((${height}px - 100%) * ${transform}))`,
        }}
      />
      <Image
        src={image03}
        alt="promotional image"
        style={{
          transform: `translateY(calc((${height}px - 100%) * ${transform}))`,
        }}
      />
      <Overlay src={image04} alt="promotional image" />
      <GradientTop />
      <GradientBottom />
    </StyledHeroImage>
  );
};

export default HeroImage;
