import { useEffect, useState } from "react";
import imgModalBanner from "../../assets/modal-banner.jpg";

import {
  iconOpenSea,
  iconBlood,
  iconBackground,
  iconChainsaw,
  iconClothes,
  iconEyes,
  iconEyeWear,
  iconHair,
  iconMouth,
  iconSkin,
  iconWeapon,
  iconHat,
} from "../../assets";
import {
  ModalContent,
  ImageContainer,
  Image,
  Overlay,
  HiresImage,
  HiresOverlay,
  Card,
  UL,
  LI,
  Wrapper,
  Heading,
  Icon,
  Hash,
  Button,
  P,
  HeartIcon,
  ModalSwitch,
  BloodSpan,
  IconChainsaw,
  BannerImg,
  ImageContent,
  UniqueKGFRibbon,
} from "./style";

// const data2 = [
//   {
//     pixelateImage:
//       "https://i.seadn.io/gae/IY9kHTkS461url0ubWiL4XnDNMskZQK4nV8PWC-AJ5PoXXFcFheQVo4SL0tRjc44PESRJQMddPJMmdc1O0293bOK1cWaapaiX77u-NI?auto=format&w=1000",
//   },
// ];

const Modal = ({ modalOpen, kgf }: any) => {
  const hasBloodyImages = kgf["Skin"]?.includes("Bloody");
  const data = [
    {
      icon: iconBackground,
      title: "BACKGROUND",
      subtitle: kgf.Background,
    },
    {
      icon: iconEyes,
      title: "EYES",
      subtitle: kgf.Eyes,
    },
    {
      icon: iconClothes,
      title: "CLOTHING",
      subtitle: kgf.Clothes,
    },
    {
      icon: iconEyeWear,
      title: "EYEWEAR",
      subtitle: kgf.Eyewear,
    },
    {
      icon: iconMouth,
      title: "MOUTH",
      subtitle: kgf.Mouth,
    },
    {
      icon: iconHair,
      title: "HAIR",
      subtitle: kgf.Hair,
    },
    {
      icon: iconSkin,
      title: "SKIN",
      subtitle: kgf.Skin,
    },
    {
      icon: iconWeapon,
      title: "WEAPON",
      subtitle: kgf.Weapon,
    },
    {
      icon: iconHat,
      title: "Hat",
      subtitle: kgf.Hat,
    },
  ];

  const [hiresOpen, setHiresOpen] = useState(false);
  const [isBloody, setIsBloody] = useState<any>(true);
  const [isPixelated, setIsPixelated] = useState(false);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [modalOpen]);
  const handleClick = () => {
    setIsBloody((prevState: any) => !prevState);
  };
  const handleClickPixelated = () => {
    setIsPixelated((prevState) => !prevState);
  };

  const toggleHires = () => {
    setHiresOpen(!hiresOpen);
  };

  const switchModal = () => {
    if (!hasBloodyImages) return null;

    return (
      <ModalSwitch isBloody={isBloody}>
        {hasBloodyImages && (
          <button
            onClick={handleClick}
            className="heart-icon"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}
          >
            <BloodSpan>Bloody:</BloodSpan>
            <HeartIcon src={iconBlood} alt="iconBackground" />
          </button>
        )}
        <article style={{ display: "flex" }}>
          <span
            style={{
              fontSize: "12px",
              cursor: "pointer",
              fontWeight: 600,
              marginRight: "5px",
              color: `${isPixelated ? "var(--primary)" : "var(--main)"}`,
            }}
            onClick={handleClickPixelated}
          >
            &nbsp;
          </span>
          {/* {hasBloodyImages && (
            <button
              onClick={handleClickPixelated}
              style={{
                position: "relative",
                width: "50px",
                height: "27px",
                borderRadius: "20px",
                backgroundColor: isPixelated ? "var(--primary)" : "#5f5f5f66",
                transition: "background-color 0.2s ease-in-out",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "2px",
                  left: isPixelated ? "26px" : "3px",
                  width: "22px",
                  height: "22px",
                  borderRadius: "50%",
                  backgroundColor: "white",
                  boxShadow: "0 0 2px 1px rgba(0, 0, 0, 0.1)",
                  transition: "left 0.2s ease-in-out",
                }}
              />
            </button>
          )} */}
        </article>
      </ModalSwitch>
    );
  };
  return (
    <div>
      <Wrapper onClick={(e) => e.stopPropagation()}>
        <div className="modal-body">
          <BannerImg src={imgModalBanner} alt="imgModalBanner" />
          <Button onClick={modalOpen}>x</Button>
          <ModalContent>
            <aside>
              <Card>
                <article className="image-card">
                  <Hash>
                    <span>KILLER GF</span> <b>{"#" + kgf.id}</b>
                  </Hash>
                  {/* <Heading>
                    <span>Owned by: <b>John Doe</b></span></Heading> */}
                </article>
                <div>
                  <a
                    href={`https://opensea.io/assets/ethereum/0x6be69b2a9b153737887cfcdca7781ed1511c7e36/${kgf.id}`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Icon src={iconOpenSea} alt="star" />
                  </a>
                </div>
              </Card>
              <UL>
                {data.map((item, index) => {
                  const { icon, title, subtitle } = item;
                  return (
                    <LI key={index}>
                      <Icon src={icon} alt={title} />
                      <Heading>
                        <P>{title}</P>
                        <h4>{subtitle || "None"}</h4>
                      </Heading>
                    </LI>
                  );
                })}
              </UL>
              {switchModal()}
            </aside>
          </ModalContent>
          <div>
            {kgf["KILLER GF"] ? (
              <UniqueKGFRibbon>{kgf["KILLER GF"]}</UniqueKGFRibbon>
            ) : (
              ""
            )}
          </div>
          <ImageContainer>
            <ImageContent>
              <Card>
                <article className="mobile-hash">
                  <Hash>
                    <span>KILLER GF</span> <b>{"#" + kgf.id}</b>
                  </Hash>
                  {/* <Heading>
                        <span>Owned by: <b>John Doe</b></span></Heading> */}
                </article>
                <div className="image-icon">
                  <a href="/" target="_blank">
                    <Icon src={iconOpenSea} alt="star" />
                  </a>
                </div>
              </Card>
            </ImageContent>
            <article className="img-wrap">
              <Image
                src={
                  // isPixelated
                  //   ? image.pixelateImage
                  //   :
                  isBloody
                    ? `https://storage.googleapis.com/kgf-thumbnails/${kgf.id}.jpg`
                    : `https://storage.googleapis.com/bloody-thumbnails/${kgf.id}.jpg`
                }
                // https://uwulabs.mypinata.cloud/ipfs/QmTifGUmic9roD1BuH62QmzZTVWapUSD5m5dwcgjuaHGHQ/{ID}
                alt={isBloody ? "Bloody image 1" : "Non-Bloody image 1"}
                onClick={toggleHires}
              />
              {switchModal()}
            </article>
            <IconChainsaw src={iconChainsaw} alt="iconChainsaw" />
          </ImageContainer>
        </div>
      </Wrapper>
      <Overlay onClick={modalOpen} />
      <HiresOverlay className={hiresOpen ? "open" : ""} onClick={toggleHires}>
        <HiresImage
          src={
            isBloody
              ? `https://ipfs.io/ipfs/QmTifGUmic9roD1BuH62QmzZTVWapUSD5m5dwcgjuaHGHQ/${kgf.id}.png`
              : `https://storage.googleapis.com/bloody-full/${kgf.id}.png`
          }
          onClick={toggleHires}
        />
      </HiresOverlay>
    </div>
  );
};

export default Modal;
