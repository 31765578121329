import styled from "styled-components";

import twitter from "../assets/socials/twitter.svg";
import discord from "../assets/socials/discord.svg";
import medium from "../assets/socials/medium.svg";
import etherscan from "../assets/socials/etherscan.svg";
import opensea from "../assets/socials/opensea.svg";
import instagram from "../assets/socials/instagram.svg";
import { OPENSEA_LINK } from "../app/globals";

const Fade = require("react-reveal/Fade");

interface SocialType {
  image: string;
  link: string;
}

const socials: SocialType[] = [
  {
    image: opensea,
    link: OPENSEA_LINK,
  },
  {
    image: etherscan,
    link: "https://etherscan.io/address/0x6bE69b2A9B153737887CfcdCa7781eD1511C7e36#code",
  },
  {
    image: twitter,
    link: "https://twitter.com/killergfnft",
  },
  {
    image: discord,
    link: "https://discord.gg/tUPgA3D5xw",
  },
  {
    image: medium,
    link: "https://killergf.medium.com",
  },
  {
    image: instagram,
    link: "https://www.instagram.com/killergfnft/",
  },
];

const StyledSocials = styled.div`
  display: flex;
  align-items: center;
  margin-top:1.5rem;
`;

const SocialLink = styled.a`
  margin: 0 0.8rem;
`;

interface SocialProps {
  small: boolean;
}

const SocialImage = styled.img`
  height: ${(props: SocialProps) => (props.small ? "2.8rem" : "3.3rem")};
`;

const FollowUs = styled.p`
  font-weight: 700;
  font-size: 1.8rem;
  color: var(--main);
  padding-bottom: 1.4rem;
  text-transform: uppercase;
`;

interface Props {
  showHeader?: boolean;
}

const Socials = ({ showHeader }: Props) => {
  return (
    <Fade bottom>
      <>
        {showHeader && <FollowUs>follow us</FollowUs>}
        <StyledSocials>
          {socials.map((social: SocialType, index: number) => (
            <SocialLink
              key={index}
              href={social.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialImage small={index < 2} src={social.image} />
            </SocialLink>
          ))}
        </StyledSocials>
      </>
    </Fade>
  );
};

export default Socials;
