import styled from "styled-components";
import { Link } from "react-router-dom";

import logo from "../assets/logos/logo.png";

const Fade = require("react-reveal/Fade");

const Container = styled.div`
  /* z-index: 3; */
  cursor: pointer;
`;

const StyledLogo = styled.img`
  position: relative;
  height: 6.8rem;
  /* z-index: 1000; */
  margin-top: 1rem;

  @media only screen and (max-width: 750px) {
    height: 6.2rem;
  }
`;

const Logo = () => {
  return (
    <Container>
      <Link to="/">
        <Fade bottom styles={{ display: "none" }}>
          <StyledLogo src={logo} alt="Killer GF Logo" />
        </Fade>
      </Link>
    </Container>
  );
};

export default Logo;
