import styled from "styled-components";
import exit from "../assets/ui/exit.svg";
import Button from "./Button";

const StyledPopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
`;

const Background = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
`;

const Container = styled.div`
  position: relative;
  width: 80vw;
  max-width: 50rem;
  height: auto;
  background-color: white;
  padding: 2.4rem 3.2rem;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 600px) {
    padding: 2.3rem 2.3rem;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Header = styled.div`
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 2rem;
  color: var(--main);

  @media only screen and (max-width: 600px) {
    font-size: 2rem;
  }
`;

const ExitButton = styled.button`
  cursor: pointer;
`;

const Exit = styled.img`
  height: 1.8rem;

  @media only screen and (max-width: 600px) {
    height: 1.6rem;
  }
`;

const SubHeader = styled.div`
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: var(--main);
  text-align: center;
`;

interface Props {
  show: boolean;
  close: () => void;
  header?: string;
  subHeader?: string;
  buttonText?: string;
  buttonAction?: () => void;
  content?: JSX.Element;
}

const Popup = ({
  show,
  close,
  header,
  subHeader,
  buttonText,
  buttonAction,
  content,
}: Props) => {
  if (!show) return <></>;

  return (
    <StyledPopup>
      <Background />
      <Container>
        <HeaderContainer>
          <Header>{header || ""}</Header>
          <div>
            <ExitButton onClick={() => close()}>
              <Exit src={exit} />
            </ExitButton>
          </div>
        </HeaderContainer>
        {subHeader && <SubHeader>{subHeader}</SubHeader>}
        {content && content}
        {buttonText && buttonAction && (
          <Button text={buttonText} click={buttonAction} />
        )}
      </Container>
    </StyledPopup>
  );
};

export default Popup;
