import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import HomePage from "./pages/home/HomePage";
import NotFoundPage from "./pages/not-found/NotFoundPage";
import { Mainnet, Config, DAppProvider } from "@usedapp/core";
import Error from "./components/Error";
import LicensePage from "./pages/License";
import CyberRunnerPage from "./pages/CyberRunner";
import GalleryPage from "./pages/gallery";

const config: Config = {
  readOnlyChainId: Mainnet.chainId,
  readOnlyUrls: {
    [Mainnet.chainId]:
      "https://mainnet.infura.io/v3/d7db9eb3e5254cde829b69df54cd664f",
  },
};

const App = (): JSX.Element => {
  return (
    <DAppProvider config={config}>
      <Router>
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route exact path="/license">
            <LicensePage />
          </Route>
          <Route exact path="/cyber-run">
            <CyberRunnerPage />
          </Route>
          <Route exact path="/gallery">
            <GalleryPage />
          </Route>
          <Route>
            <NotFoundPage />
          </Route>
        </Switch>
      </Router>
      <Error />
    </DAppProvider>
  );
};

export default App;
