import { useState } from "react";
import styled from "styled-components";
import NavItems from "./NavItems";
import Socials from "./Socials";

const StyledHamburger = styled.button`
  position: relative;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  height: 2.3rem;
  width: 3.2rem;
  /* margin-right: 2.6rem; */
  z-index: 2;

  @media only screen and (max-width: 750px) {
    display: flex;
    margin-right: 0;
  }

  @media only screen and (min-width: 601px) and (max-width: 1200px) {
    display: flex;
  }
`;

interface LineProps {
  open: boolean;
  right?: boolean;
  middle?: boolean;
}

const Line = styled.div`
  width: 100%;
  height: 3px;
  border-radius: 1px;
  background-color: var(--main);

  transition: all 0.3s;
  transform: ${(props: LineProps) =>
    !props.open
      ? "rotate(0deg)"
      : props.right
      ? "rotate(-45deg) translate(0.7rem, -0.7rem)"
      : "rotate(45deg) translate(0.7rem, 0.7rem)"};
  opacity: ${(props: LineProps) => (props.open && props.middle ? "0" : "1")};
`;

interface SliderProps {
  open: boolean;
}

const Slider = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg);
  padding-top: 5rem;
  padding-bottom: 3rem;

  transition: all 0.3s;
  transform: ${(props: SliderProps) =>
    props.open ? "translateX(0)" : "translateX(100%)"};
`;

const Hamburger = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Slider open={open}>
        <NavItems />
        <Socials />
      </Slider>
      <StyledHamburger onClick={() => setOpen(!open)}>
        <Line open={open} />
        <Line middle open={open} />
        <Line right open={open} />
      </StyledHamburger>
    </>
  );
};

export default Hamburger;
