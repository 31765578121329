export const INFURA_ID = "d501e9f20ad0477e84e7f1b448032de0";
export const OPENSEA_LINK = "https://opensea.io/collection/killergf";

interface GlobalsType {
  LAUNCH_DATE: Date;
  REVEAL_DATE: Date;
  SIZE: number;
  NFT_CONTRACT: string;
  SALE_CONTRACT: string;
}

export const ETH_GLOBALS: GlobalsType = {
  LAUNCH_DATE: new Date(1643070600000),
  REVEAL_DATE: new Date(1643436000000),
  SIZE: 7777,
  NFT_CONTRACT: "0x6bE69b2A9B153737887CfcdCa7781eD1511C7e36",
  SALE_CONTRACT: "0x3a00c557a2a0b7d4c5e05679c7904A970e5caccd",
};

export const RINKEBY_GLOBALS: GlobalsType = {
  LAUNCH_DATE: new Date(1643056500000),
  REVEAL_DATE: new Date(1643416200000),
  SIZE: 7777,
  NFT_CONTRACT: "0x5AF528abb30F2c6724626fb8626cDB539E2572E2",
  SALE_CONTRACT: "0x86c0d968BA9aF369A0321b15570838eECe961709",
};
