import React from "react";
import styled from "styled-components";
import GlobalStyles from "../styles/GlobalStyles";
import Header from "../components/Header";
import License from "../components/License";

const StyledHomePage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  min-height: 100vh;
  background-color: var(--bg);
  overflow: hidden;
`;

const LicensePage = () => {
  return (
    <StyledHomePage>
      <GlobalStyles />
      <Header />
      <License />
    </StyledHomePage>
  );
};

export default LicensePage;
