import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";

import { Header2 } from "../../styles/Headers";
import SubHeader from "../../styles/SubHeader";

import laur from "../../assets/featured-artists/laur.jpg";
import benangbaja from "../../assets/featured-artists/benangbaja.jpg";
import yueko from "../../assets/featured-artists/yueko.jpg";
import cirilla from "../../assets/featured-artists/cirilla.jpg";
import robek from "../../assets/featured-artists/robek.png";
import _0010 from "../../assets/featured-artists/numbers.jpg";
import naokiSaito from "../../assets/featured-artists/naoki-saito.jpg";
import sashimi from "../../assets/featured-artists/sashimi.jpg";
import seekgoliath from "../../assets/featured-artists/seekgoliath.png";
import anriokita from "../../assets/featured-artists/anriokita.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import bg from "../../assets/featured-artists/bg.png";
import twitter from "../../assets/socials/twitter.svg";
import fnd from "../../assets/socials/foundation.svg";

const Fade = require("react-reveal/Fade");

interface ArtistType {
  name: string;
  descripion?: string;
  profile: string;
  twitter: string;
  nftLink: string;
}

const artists: ArtistType[] = [
  {
    name: "Laur",
    descripion:
      "Laur is the co-founder and artist for uwucrew. She has been a pioneer in the Anime NFT space and has inspired new Anime projects like Killer GF to grow. Her focus on community and positivity has made uwucrew what it is today. A prominent collector in her own right, she has grown her collection by supporting many smaller creators.",
    profile: laur,
    twitter: "https://twitter.com/fungibleartist",
    nftLink: "https://foundation.app/@KILLERGF/kgfgen/1",
  },
  {
    name: "Benangbaja",
    descripion:
      "Benangbaja is a visual artist based in Makassar, Indonesia. They have been an active artist since 2011. Oftentimes their work is about daily life and internet culture. The use of firm lines and bright colors has become a distinctive feature in their work. Outside of art they also have a wife and a son.",
    profile: benangbaja,
    twitter: "https://twitter.com/benangbaja",
    nftLink: "https://foundation.app/@KILLERGF/kgfgen/2",
  },
  {
    name: "Yueko",
    descripion:
      "Yueko is an illustrator from New Zealand known for their Asian inspired art, often referencing anime. She embraces a strong, elegant style with soft, regal undertones and powerful feminine figures.",
    profile: yueko,
    twitter: "https://twitter.com/yueko__",
    nftLink: "https://foundation.app/@KILLERGF/kgfgen/3",
  },
  {
    name: "Cirilla",
    descripion:
      "Cirilla Lin is a Freelance illustrator from Taiwan. They have been drawing for over 4 years and are known for drawing figures and scenery. They are prominent on Twitter amassing a following of over 200,000 fans.",
    profile: cirilla,
    twitter: "https://twitter.com/as0680992",
    nftLink: "https://foundation.app/@KILLERGF/kgfgen/4",
  },
  {
    name: "Robek",
    descripion:
      "Robek is also one of the largest anime art collectors in the NFT space. He has created art for Curio Cards in 2017 and co-founded weeb3dao. Check out his blog posts!",
    profile: robek,
    twitter: "https://twitter.com/robek_world",
    nftLink: "https://foundation.app/@KILLERGF/kgfgen/5",
  },
  {
    name: "0010",
    descripion:
      "0010 is an illustrator with a psychedelic and Anime influence trying to explore matters of the intangible. An NFT veteran since Curio Cards, 0010 has developed an iconic style that has reached the hands of many notable collectors.",
    profile: _0010,
    twitter: "https://twitter.com/dhjs_0010",
    nftLink: "https://foundation.app/@KILLERGF/kgfgen/6",
  },
  {
    name: "Naoki Saito",
    descripion:
      "Naoki Saito is a Japanese Illustrator best known for his work as a certified Pokémon Card Illustrator. His career history is incredibly impressive, on top of several years of Pokémon work, his career also includes work for Dragolia Lost and Hatsune Miku.",
    profile: naokiSaito,
    twitter: "https://twitter.com/_NaokiSaito",
    nftLink: "https://foundation.app/@KILLERGF/kgfgen/7",
  },
  {
    name: "Sashimi",
    descripion:
      "Sashimi is a Japanese Illustrator who is known for her cute and vibrant style, she is a community favorite among uwucrew, Anifam NFT, and other large anime art NFT communities!",
    profile: sashimi,
    twitter: "https://twitter.com/ssm_a_u",
    nftLink: "https://foundation.app/@KILLERGF/kgf-charity/1",
  },
  {
    name: "Seekgoliath",
    descripion:
      "Seekgoliath is an artist from South East Asia who we selected as a guest artist for KGF CHARITY as a reward for winning the Derivative Art Contest! Their charity of choice was the Animal Impact Fund, an index fund provided by The Giving Block consisting of dozens of nonprofits that all protect, foster, and rehabilitate animals.",
    profile: seekgoliath,
    twitter: "https://twitter.com/seekgoliath",
    nftLink: "https://foundation.app/@KILLERGF/kgf-charity/2",
  },
  {
    name: "Anri Okita",
    descripion:
      "Anri Okita (Japanese: 沖田杏梨), an English-born Japanese actress, singer, and spiritual NFT artist! Her charity of choice was the Disasters & Conflict Impact Fund, an index fund provided by The Giving Block consisting of several nonprofits that all strive to improve the health and lives of people affected by emergencies such as disasters, poverty, and deadly conflict.",
    profile: anriokita,
    twitter: "https://twitter.com/AnriOkita_real",
    nftLink: "https://foundation.app/@KILLERGF/kgf-charity/3",
  },
];

const StyledFeature = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 110rem;
  margin: 12rem 0;
  align-items: center;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    margin: 5rem 0;
    padding: 0 3rem;
  }

  @media only screen and (min-width: 601px) and (max-width: 1200px) {
    margin: 5rem 0;
    padding: 0 3rem;
  }

  .dot {
    background: #e84888 !important;
    box-shadow: none !important;
  }

  div:nth-child(1) {
    width: 100%;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 3rem;
`;

const Artist = styled.div`
  display: flex;
  width: 100%;
`;

const Bg = styled.img`
  height: 40rem;
  @media only screen and (max-width: 600px) {
    height: 60rem;
  }
`;

const ArtistImage = styled.img`
  height: 100% !important;
  width: auto !important;

  @media only screen and (max-width: 600px) {
    height: auto !important;
    width: 100% !important;
  }
`;

const CoolStuff = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background: transparent !important;
  opacity: 1 !important;
  padding: 0 !important;
  padding-top: 3.8rem !important;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    margin-bottom: 2rem;
  }
`;

const ContentSection = styled.div`
  flex: 1;
  height: 100%;
  background: white;
  padding-top: 7rem;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;

  padding: 3rem;
  @media only screen and (max-width: 600px) {
    padding: 0rem;
  }
`;

const Content = styled.div`
  width: 100%;
  border-radius: 1rem;
  padding: 1.5rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background: var(--bg);
  @media only screen and (max-width: 600px) {
    background: none;
  }
`;

const CoolHeader = styled.div`
  color: #e74988;
  font-weight: 700;

  font-size: 2.4rem;
  margin-bottom: 1rem;
  @media only screen and (max-width: 600px) {
    margin-bottom: 0.7rem;
    font-size: 2rem;
  }
`;

const CoolBio = styled.div`
  color: black;
  font-weight: 600;
  text-align: left;

  font-size: 1.7rem;
  @media only screen and (max-width: 600px) {
    font-size: 1.3rem;
  }
`;

const Header = styled(Header2)`
  white-space: normal;
  line-height: 1.2;

  max-width: calc(100% - 6rem);
  @media only screen and (max-width: 600px) {
    max-width: none;
  }
`;

const Link = styled.a`
  font-size: 2rem;
  font-weight: 500;
  text-decoration: underline;

  @media only screen and (max-width: 600px) {
    font-size: 1.6rem;
  }

  @media only screen and (min-width: 601px) and (max-width: 1200px) {
    font-size: 1.8rem;
  }
`;

const ListItem = styled(SubHeader)`
  margin-top: 0.6rem;
  margin-left: 1rem;
`;

const TwitterLink = styled.a`
  cursor: pointer;
`;

const TwitterIcon = styled.img`
  height: 3.5rem;

  @media only screen and (max-width: 600px) {
    height: 2.8rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
`;


const FndLink = styled.a`
  cursor: pointer;
`;

const FndIcon = styled.img`
  height: 1.8rem;

  @media only screen and (max-width: 600px) {
    height: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
`;

const Feature = () => {
  return (
    <StyledFeature id="artist-scroll">
      <TextContainer>
        <Fade bottom>
          <Header>Monthly Charity Auctions</Header>
        </Fade>
        <Fade bottom>
          <SubHeader>
            Every month on the{" "}
            <Link
              href="https://foundation.app/@KILLERGF"
              target="_blank"
              rel="noopener noreferrer"
            >
              Killer GF Foundation
            </Link>{" "}
            account, we feature a guest artist who draws their own original Killer GF! 
            KGF Holders can also claim a free gift NFT from the guest artist during their auction!
          </SubHeader>
          <br/>
          <SubHeader>
            The 1/1s are auctioned on Foundation with the proceeds going to the
            following split:
          </SubHeader>
          <ListItem>- 33% to KGF Team</ListItem>
          <ListItem>- 33% to the Artist</ListItem>
          <ListItem>- 33% to Charity (Artists pick!)</ListItem>
        </Fade>
      </TextContainer>
      <Carousel
        autoPlay
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        interval={7_000}
      >
        {artists.map((a: ArtistType,index:number) => (
          <Artist key={index}>
            <Bg src={bg} />
            <CoolStuff className="legend">
              <ArtistImage 
                src={a.profile} 
                alt="Feature artist image" 
              />
              <ContentSection>
                <Content>
                  <CoolHeader>{a.name}</CoolHeader>
                  <CoolBio>{a.descripion}</CoolBio>
                </Content>
                <TwitterLink
                  href={a.twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TwitterIcon src={twitter} alt="Twitter icon" />
                </TwitterLink>
                <FndLink
                  href={a.nftLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FndIcon src={fnd} alt="FND icon" />
                </FndLink>
              </ContentSection>
            </CoolStuff>
          </Artist>
        ))}
      </Carousel>
    </StyledFeature>
  );
};

export default Feature;
