import styled from "styled-components";
import { Unity, useUnityContext } from "react-unity-webgl";
import { useCallback, useEffect, useState } from "react";
import { useEthers } from "@usedapp/core";
import { ethers } from "ethers";
import axios from "axios";
import { useNftBalance } from "../contracts/views";
import jwt from 'jsonwebtoken';
const API_URL = "https://66.94.117.199:5000";

const StyledLicense = styled.div`
  padding: 0 5rem;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--bg-02);

  @media (max-width: 720px) {
    padding: 2rem;
    padding-top: 7rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 150em;
`;

const Loading = styled.h1`
  font-weight: 700;
  color: var(--sub);
  text-transform: uppercase;
  margin-bottom: 0.3rem;
  margin-top: 3rem;

  font-size: 2.4rem;
  @media only screen and (max-width: 600px) {
    font-size: 1.6rem;
  }
`;

const CyberRunner = () => {
  const mainnetProvider = ethers.getDefaultProvider("homestead", {
    etherscan: "PH8C9KSW5BHQR6295F4XVFJET1HHPX1A2Z",
    infura: "f297cafb132a4f14a180b7758ef4156a",
    alchemy: "PkvRpgbfyENlDIJuqjk8r2oPyP49uukF",
    pocket: "e2fcb21d28414f7e08f3ce42969baf91"
  });
  const { activateBrowserWallet, deactivate, account } = useEthers();
  const [userId, setUserId] = useState(0);
  const userBalance = useNftBalance();
  const {
    UNSAFE__detachAndUnloadImmediate: detachAndUnloadImmediate,
    unityProvider,
    loadingProgression,
    isLoaded,
    addEventListener,
    removeEventListener,
    sendMessage
  } = useUnityContext({
    loaderUrl: "Kiwi/Build/Kiwi.loader.js",
    dataUrl: "Kiwi/Build/Kiwi.data",
    frameworkUrl: "Kiwi/Build/Kiwi.framework.js",
    codeUrl: "Kiwi/Build/Kiwi.wasm",
    streamingAssetsUrl: "Kiwi/StreamingAssets",
  });

  const getRecord = useCallback(async () => {
    await mainnetProvider.lookupAddress(account + "").then((addr) => {
      console.log(addr);
      axios.post(API_URL + "/api/auth/joingame", {
        wallet_address: account,
        ens_name: addr,
        nft_balance: userBalance
      })
        .then((res) => {
          localStorage.setItem("JWT_TOKEN", JSON.stringify(res.data.accessToken));
          sendMessage("DataController", "SetWA", !addr ? account + "" : addr + "");
          sendMessage("DataController", "SetUserID", parseInt(res.data.userId));
          sendMessage("DataController", "SetUnlockedInfo", parseInt(res.data.unlocked));
          setUserId(res.data.userId);
          axios.get(API_URL + "/api/record/getRecord")
            .then(records => {
              sendMessage("LoginController", "SetHistory", JSON.stringify(records.data));
            });
        });
    }).catch(() => {
      sendMessage("DataController", "SetUnlockedInfo", 1);
    })
  }, [account, sendMessage, mainnetProvider, userBalance]);

  const connectWallet = useCallback(() => {
    if (!account)
      activateBrowserWallet();
    else
      getRecord();
  }, [account, activateBrowserWallet, getRecord]);

  const disconnectWallet = useCallback(() => {
    if (!account)
      deactivate();
    else
      sendMessage("LoginController", "SetDisconnectWallet");
  }, [account, deactivate, sendMessage]);

  const setRecord = useCallback((_scoreData: string) => {
    const token = localStorage.getItem('JWT_TOKEN');
    const param = {
      user_id: userId,
      scoreData: _scoreData
    }
    let data = jwt.sign(param, "killergf-secret-key", { expiresIn: 60 });
    if (userBalance >= 0) {
      axios.post(
        API_URL + "/api/record/setRecord", { data },
        {
          headers: {
            "x-access-token": `${token}`,
            "content-type": "application/json"
          }
        }
      );
    }
  }, [userId, userBalance]);

  const getAchievement = useCallback((_page) => {
    axios.get(API_URL + "/api/record/getGirl?user_id=" + userId)
      .then(records => {
        sendMessage("Achievement", "SetTarget", JSON.stringify(records.data));
      });
  }, [userId, sendMessage]);

  useEffect(() => {
    addEventListener("ReactSetRecord", setRecord);
    addEventListener("DisconnectWallet", disconnectWallet);
    addEventListener("ConnectWallet", connectWallet);
    addEventListener("GetAchievement", getAchievement);
    return () => {
      removeEventListener("ReactSetRecord", setRecord);
      removeEventListener("ConnectWallet", connectWallet);
      removeEventListener("DisconnectWallet", disconnectWallet);
      removeEventListener("GetAchievement", getAchievement);
    };
  }, [getAchievement, addEventListener, removeEventListener, connectWallet, disconnectWallet, setRecord]);

  useEffect(() => {
    return () => {
      try {
        detachAndUnloadImmediate();
      } catch { }
    };
  }, [detachAndUnloadImmediate]);

  return (
    <StyledLicense>
      {!isLoaded && (
        <Loading>Loading Application... {Math.round(loadingProgression * 100)}%</Loading>
      )}

      <Content>
        <Unity unityProvider={unityProvider} style={{ width: 1280, height: 720 }} />
      </Content>
    </StyledLicense>
  );
};

export default CyberRunner;
