import { useContractCall, useEthers } from "@usedapp/core";
import { utils } from "ethers";

import saleAbi from "./sale.json";
import nftAbi from "./killer-gf.json";
import useGlobals from "../app/hooks/use-globals";
import { useTick } from "../app/hooks/use-tick";
import { getMaxPerTransactionForWave } from "./contractHelper";

export const useCurrentWave = (): number => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const tick = useTick();
  const saleStartTime = useSaleStartTime();
  const waveTimeLength = useWaveTimeLength();
  const now = new Date();
  if (now < saleStartTime) return 1;
  const liveTime = now.getTime() - saleStartTime.getTime();
  return Math.floor(liveTime / waveTimeLength) + 1;
};

export const useTicketBalance = (): number => {
  const globals = useGlobals();
  const { account } = useEthers();

  const [ticketBalance] = useContractCall(
    account && {
      abi: new utils.Interface(saleAbi),
      address: globals.SALE_CONTRACT,
      method: "balanceOf",
      args: [account],
    }
  ) ?? [0];
  return Number(ticketBalance.toString());
};

export const useSaleStartTime = (): Date => {
  const globals = useGlobals();

  const [saleStartTime] = useContractCall({
    abi: new utils.Interface(saleAbi),
    address: globals.SALE_CONTRACT,
    method: "startTime",
    args: [],
  }) ?? [null];
  if (!saleStartTime) return new Date();
  return new Date(Number(saleStartTime.toString()) * 1000);
};

export const useDevSupply = (): number => {
  const globals = useGlobals();

  const [devSupply] = useContractCall({
    abi: new utils.Interface(saleAbi),
    address: globals.SALE_CONTRACT,
    method: "devSupply",
    args: [],
  }) ?? [0];
  return Number(devSupply.toString());
};

export const useAmountForSale = (): number => {
  const globals = useGlobals();
  const devSupply = useDevSupply();

  const [amountForSale] = useContractCall({
    abi: new utils.Interface(saleAbi),
    address: globals.SALE_CONTRACT,
    method: "amountForSale",
    args: [],
  }) ?? [0];
  return Number(amountForSale.toString()) + devSupply;
};

export const useAmountSold = (): number => {
  const globals = useGlobals();
  const devSupply = useDevSupply();

  const [amountSold] = useContractCall({
    abi: new utils.Interface(saleAbi),
    address: globals.SALE_CONTRACT,
    method: "amountSold",
    args: [],
  }) ?? [0];
  return Number(amountSold.toString()) + devSupply;
};

export const useSoldOut = (): boolean => {
  const amountForSale = useAmountForSale();
  const amountSold = useAmountSold();
  if (amountForSale === 0) return false;
  return amountForSale === amountSold;
};

export const useWaveTimeLength = (): number => {
  const globals = useGlobals();

  const [waveTimeLength] = useContractCall({
    abi: new utils.Interface(saleAbi),
    address: globals.SALE_CONTRACT,
    method: "waveTimeLength",
    args: [],
  }) ?? [1];
  return Number(waveTimeLength.toString()) * 1000;
};

export const useNextWave = (): Date => {
  const saleStartTime = useSaleStartTime();
  const waveTimeLength = useWaveTimeLength();
  const currentWave = useCurrentWave();
  const isLive = useIsLive();
  if (!isLive) return saleStartTime;
  saleStartTime.setMilliseconds(
    saleStartTime.getMilliseconds() + waveTimeLength * currentWave
  );
  return saleStartTime;
};

export const useBuyPrice = (): number => {
  const globals = useGlobals();

  const [buyPrice] = useContractCall({
    abi: new utils.Interface(saleAbi),
    address: globals.SALE_CONTRACT,
    method: "buyPrice",
    args: [],
  }) ?? [1];
  return Number(buyPrice.toString());
};

export const useUwuPrice = (): number => {
  const globals = useGlobals();

  const [uwuPrice] = useContractCall({
    abi: new utils.Interface(saleAbi),
    address: globals.SALE_CONTRACT,
    method: "uwuPrice",
    args: [],
  }) ?? [1];
  return Number(uwuPrice.toString());
};

export const useCurrentMaxPerTransaction = (): number => {
  const currentWave = useCurrentWave();
  return getMaxPerTransactionForWave(currentWave);
};

export const useIsLive = (): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const tick = useTick();
  const saleStartTime = useSaleStartTime();
  return new Date() > saleStartTime;
};

export const useHasDoneWave = (): boolean => {
  const globals = useGlobals();
  const currentWave = useCurrentWave();
  const { account } = useEthers();

  const [, wavePurchases] = useContractCall({
    abi: new utils.Interface(saleAbi),
    address: globals.SALE_CONTRACT,
    method: "wavePurchaseInfo",
    args: [currentWave - 1, account],
  }) ?? [0, 0];
  return Number(wavePurchases.toString()) > 0;
};

export const useNftBalance = (): number => {
  const globals = useGlobals();
  const { account } = useEthers();

  const [nftBalance] = useContractCall({
    abi: new utils.Interface(nftAbi),
    address: globals.NFT_CONTRACT,
    method: "balanceOf",
    args: [account],
  }) ?? [0];
  return Number(nftBalance.toString());
};
