import styled, { keyframes } from 'styled-components';

// Define the shimmer animation
const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

// Define the Skeleton component
const Skeleton = styled.div`
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${shimmer};
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  position: relative;
  min-height: 200px;
`;

const SkeletonCards = ({ count }: {count: number}) => {
  return (
    <>
      {[...Array(count)].map((_, index) => (
        <ImageContainer key={index}>
          <Skeleton />
          <Flex>
            <Text>KGF</Text>
            <Number>#</Number>
          </Flex> 
        </ImageContainer>
      ))}
    </>
  );
}

export default SkeletonCards;

const ImageContainer = styled.div`
  text-align: center;
  border: 1px solid #ECECEC;
  background-color: #fff;
  overflow: hidden;
`;
const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding:12px;
`;

const Text = styled.h6`
  font-size: 2rem;
  font-weight: 700;
  color: var(--primary);
  font-family: 'Oswald', sans-serif;
  /* letter-spacing: 1.5px; */
`;

const Number = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
  color: #828282;
  margin-left: 0.5rem;
`;
