import styled from "styled-components";
import Artist from "./Artist";
import Gallery from "./Gallery";
import Hero from "./Hero";
import HeroImage from "./HeroImage";
import OtherWork from "./OtherWork";
import Roadmap from "./Roadmap";
import Team from "./Team";
import Story from "./Story";
import Feature from "./Feature";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const StyledHomePage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  min-height: 100vh;
  background-color: var(--bg);
  overflow: hidden;
`;

const HomePage = () => {
  return (
    <StyledHomePage>
      <Header />
      <Hero />
      <HeroImage />
      <Story />
      <Gallery />
      <Roadmap />
      <Feature />
      <Artist />
      <OtherWork />
      <Team />
      <Footer />
    </StyledHomePage>
  );
};

export default HomePage;
