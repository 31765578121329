import { useState, useCallback, useEffect, useRef } from "react";
import {
  where,
  collection,
  query,
  getCountFromServer,
  orderBy,
  startAfter,
  getDocs,
  limit,
  QueryDocumentSnapshot,
  DocumentData,
} from "firebase/firestore";
import { db } from "../../firebase";

interface FilterSelections {
  [category: string]: (number | string)[];
}

const BATCH_SIZE = 48;

const useImages = (activeFilters: FilterSelections | null) => {
  const [imageBatches, setImageBatches] = useState<{ [key: string]: any[] }>(
    {}
  );
  const [imageTotalCount, setImageTotalCount] = useState(0);
  const [lastDoc, setLastDoc] = useState<{
    [key: string]: QueryDocumentSnapshot<DocumentData> | null;
  }>({});
  const [hasMore, setHasMore] = useState<{ [key: string]: boolean }>({});

  const filterKeyRef = useRef<string>("{}");

  const [error, setError] = useState("");

  const fetchImages = useCallback(async () => {
    try {
      let collectionRef = collection(db, "gallery");

      const allFilterQueries = [];
      let filterArr = [];

      for (const category in activeFilters) {
        if (Object.keys(activeFilters[category]).length !== 0) {
          const singleQuery = where(category, "in", activeFilters[category]);
          allFilterQueries.push(singleQuery);
          filterArr.push(activeFilters[category]);
        }
      }

      const filterKey = filterArr.length ? JSON.stringify(filterArr) : "{}";
      filterKeyRef.current = filterKey;

      const firestoreQuery = lastDoc[filterKey]
        ? query(
            collectionRef,
            ...allFilterQueries,
            orderBy("id", "asc"),
            startAfter(lastDoc[filterKey]),
            limit(BATCH_SIZE)
          )
        : query(
            collectionRef,
            ...allFilterQueries,
            orderBy("id", "asc"),
            limit(BATCH_SIZE)
          );

      const snapshot = await getDocs(firestoreQuery);

      const countQuery = query(collectionRef, ...allFilterQueries);
      const totalCount = await getCountFromServer(countQuery);
      setImageTotalCount(totalCount.data().count);

      setHasMore((prevState) => ({
        ...prevState,
        [filterKey]: !(snapshot.empty || snapshot.docs.length < BATCH_SIZE),
      }));

      const newBatch = [
        ...(imageBatches[filterKey] || []),
        ...snapshot.docs.map((doc) => doc.data()),
      ];
      setImageBatches((prevState) => ({ ...prevState, [filterKey]: newBatch }));

      setLastDoc((prevState) => ({
        ...prevState,
        [filterKey]: snapshot.docs[snapshot.docs.length - 1],
      }));
    } catch (error: any) {
      console.error("Error fetching images:", error);
      setError(error);
    }
  }, [activeFilters, lastDoc, imageBatches]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchImages();
  }, [activeFilters]); // eslint-disable-line react-hooks/exhaustive-deps
  return {
    images: imageBatches[filterKeyRef.current],
    imageTotalCount: imageTotalCount,
    fetchMore: fetchImages,
    hasMore: hasMore[filterKeyRef.current],
    error,
    resetError: setError,
  };
};

export default useImages;
