import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/Button";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const StyledNotFoundPage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 2rem 4rem;
  min-height: 100vh;

  @media only screen and (max-width: 600px) {
    padding: 6rem 2.3rem;
  }
`;

const Content = styled.div`
  position: relative;
  max-width: 120rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1;
`;

const Number = styled.h1`
  font-family: Impact, "Anton", sans-serif;
  font-size: 23rem;
  line-height: 8.5rem;
  color: var(--main);
  color: var(--primary);
  max-width: 36rem;
  text-transform: uppercase;
  margin-bottom: 5rem;

  @media only screen and (max-width: 600px) {
    font-size: 20rem;
    text-align: center;
    margin-top: 3rem;
  }
`;

const Description = styled.h2`
  font-family: Impact, "Anton", sans-serif;
  font-size: 5.5rem;
  line-height: 8.5rem;
  color: var(--primary);
  color: var(--main);
  text-transform: uppercase;
  margin-bottom: 3rem;

  @media only screen and (max-width: 600px) {
    font-size: 4.5rem;
    text-align: center;
  }
`;

const NotFoundPage = () => {
  const history = useHistory();

  return (
    <StyledNotFoundPage>
      <Header />
      <Content>
        <Number>404</Number>
        <Description>page not found</Description>
        <Button text="Back to home" click={() => history.push("/")} />
      </Content>
      <Footer />
    </StyledNotFoundPage>
  );
};

export default NotFoundPage;
