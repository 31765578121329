import React from "react";
import styled from 'styled-components';
interface Props {
  number: string;
}
const GalleryCard = ({ number }: Props) => {
 return (
    <ImageContainer>
      <Image src={`https://storage.googleapis.com/kgf-thumbnails/${number}.jpg`} />
      <Flex>
        <Text>KGF</Text>
        <Number>#{number}</Number>
      </Flex>
    </ImageContainer>
  );
};

export default GalleryCard;


const ImageContainer = styled.div`
  text-align: center;
  border: 1px solid #ECECEC;
  background-color: #fff;
  overflow: hidden;
`;
const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding:12px;
`;
const Image = styled.img`
  width: 100%;
  height: auto;
  transform: scale(1);
  transition: 0.3s transform ease-in-out;
  cursor: pointer;
  :hover {
    transform: scale(1.02);
  }
`;
const Text = styled.h6`
  font-size: 2rem;
  font-weight: 700;
  color: var(--primary);
  font-family: 'Oswald', sans-serif;
  /* letter-spacing: 1.5px; */
`;
const Number = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
  color: #828282;
  margin-left: 0.5rem;
`;