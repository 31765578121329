import styled from "styled-components";
interface FilterProps {
  isBloody: boolean;
}
const Wrapper = styled.div`
  max-width: 750px;
  position: fixed;
  top: 50%;
  z-index: 5;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: fit-content;
  border-radius: 0.5rem;
  padding-block: 20px;
  .modal-body {
    display: flex;
    flex-wrap: wrap;
    &::-webkit-scrollbar {
      width: 0;
    }
  }

  @media (max-width: 1199px) {
    max-width: 65%;
  }
  @media (max-width: 991px) {
    max-width: 70%;
    height: 95%;
    .modal-body {
      height: 100%;
      flex-direction: row;
      overflow-y: auto;
      background-color: var(--white);
    }
    padding-block: 10px;
  }
  @media (max-width: 767px) {
    max-width: 80%;
    height: 90%;
    background-color: #fff;
  }
  @media (max-width: 576px) {
    max-width: 90%;
  }
`;
const BannerImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const Button = styled.button`
  position: absolute;
  right: -10px;
  top: -10px;
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 100%;
  font-size: 1.8rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s;
  line-height: 25px;
  background-color: var(--white);
  border: 2px solid #e62f6e;
  color: #e62f6e;
  z-index: 2;
  &:hover {
    background-color: #e62f6e;
    color: var(--white);
  }
  @media screen and (max-width: 991px) {
    right: -13px;
    top: -16px;
  }
`;
const ModalContent = styled.div`
  flex: 0 0 75%;
  max-width: 0 0 75%;
  padding: 0 2rem;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 0 0 100%;
    margin-top: 2rem;
    order: 1;
    height: fit-content;
  }
  @media (max-width: 576px) {
    padding: 0 1rem 0rem 1rem;
    margin-top: 0.5rem;
  }
  div {
    p {
      font-size: 1.3rem;
      margin-bottom: 5px;
      font-weight: 500;
    }
    h4 {
      color: var(--main);
      font-size: 1.4rem;
      span {
        opacity: 0.5;
      }
    }
  }

  aside {
    width: 100%;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
`;

const ModalSwitch = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  background-color: var(--white);
  border: 1px solid #bdbdbd;
  padding: 8px;
  @media screen and (max-width: 991px) {
    display: none;
    margin: 0;
  }
  article {
    display: flex;
    align-items: center;
  }
  .heart-icon {
    position: relative;
    overflow: hidden;
    transition: all 0.5s ease;
    padding: 3px 7px;
    border: 1px solid #e62f6e;
    border-radius: 5px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.35);
    background-color: ${(props: FilterProps) =>
      props.isBloody ? "var(--primary)" : "var(--white)"};
    p {
      font-weight: 600;
      color: ${(props: FilterProps) =>
        props.isBloody ? "var(--white)" : "var(--primary)"};
    }
    img {
      filter: ${(props: FilterProps) =>
        props.isBloody ? " brightness(0) invert(1)" : ""};
      margin-left: 5px;
    }

    &:hover {
      position: relative;
      top: 1px;
      left: 1px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0);
    }
  }
`;
const ImageContainer = styled.div`
  flex: 0 0 35%;
  max-width: 0 0 35%;
  position: absolute;
  right: -20%;
  bottom: 18px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 85%;
  .img-wrap {
    background-color: var(--white);
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 330px;
    margin-inline: auto;
    div {
      display: none;
      @media screen and (max-width: 991px) {
        display: flex;
      }
    }
  }
  @media screen and (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 0 0 100%;
    position: relative;
    transform: translateX(-50%);
    left: 50%;
    right: inherit;
    bottom: inherit;
    display: initial;
    height: initial;
    .img-wrap {
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 1199px) {
    right: -24%;
  }
  .image-card {
    background-color: var(--white);
    padding: 1rem;
    border: 1px solid #5f5f5f57;
    width: fit-content;
    margin-inline: auto;
  }
`;
const Image = styled.img`
  max-width: 100%;
  width: 100%;
  height: 330px;
  @media (max-width: 991px) {
    object-fit: cover;
    /* height: 324px; */
  }
  /* @media (max-width: 576px) {
    padding-inline: 10px;
  } */
`;
const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  z-index: 3;
`;
const HiresImage = styled.img`
  position: fixed;
  top: 0;
  opacity: 1;
  width: 100vw;
  height: 100vh;
  object-fit: contain;
  z-index: 1000;
`;
const HiresOverlay = styled(Overlay)`
  display: none;
  z-index: 999;

  &.open {
    display: block;
  }
`;
const Card = styled.div`
  width: 100%;
  background-color: var(--white);
  border: 1px solid #bdbdbd;
  padding: 1rem 1.6rem;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  a:first-child {
    margin-right: 10px;
  }
  @media screen and (max-width: 991px) {
    display: none;
  }
`;
const Hash = styled.h5`
  display: flex;
  align-items: center;
  span {
    display: block;
    font-size: 2rem;
    font-weight: 400;
    margin-right: 1rem;
    color: var(--primary);
    font-family: Impact, "Anton", sans-serif;
  }

  b {
    font-size: 2rem;
  }
`;
const UL = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.9rem;
  width: 100%;
  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;
const LI = styled.li`
  background-color: var(--white);
  border: 1px solid #bdbdbd;
  /* border-radius: 0.5rem; */
  padding: 1.2rem;
  display: flex;
  align-items: center;
  &:nth-child(3) {
    grid-column: 1/ 3;
  }
  &:nth-child(6) {
    grid-column: 1/ 3;
  }
  &:last-child {
    grid-column: 1/ 3;
  }
  img {
    margin-right: 1.2rem;
  }
  div {
    padding: 0;
  }
`;
const Heading = styled.div`
  b,
  span {
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--sub);
  }
  b {
    color: var(--main);
    font-weight: 600;
  }
`;
const Icon = styled.img`
  /* width: 24px;
  height: auto; */
`;
const P = styled.p`
  color: var(--primary);
`;
const HeartIcon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
const BloodSpan = styled.p`
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0 !important;
`;
const IconChainsaw = styled.img`
  max-width: 90%;
  height: auto;
  @media screen and (max-width: 991px) {
    display: none;
  }
`;
const ImageContent = styled.div`
  padding: 0 10px 10px;
  div {
    display: none;
    width: 100%;
    .mobile-hash {
      width: 100%;
    }
    .image-icon {
      justify-content: flex-end;
    }
    @media screen and (max-width: 991px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .image-icon {
    width: fit-content;
  }
`;
const UniqueKGFRibbon = styled.div`
  position: absolute;
  font-family: Impact, "Anton", sans-serif;
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  left: 1rem;
  top: -10px;
  color: #fff;
  padding: 0 5px;
  background: #111;
  transform: perspective(400px) rotateY(27deg);
  z-index: 5;

  ::after {
    content: "\\2665";
    position: absolute;
    color: #111;
    right: -1.5rem;
    top: -2.5rem;
    font-size: 1.75em;
    transform: rotate(-20deg);
  }
`;
export {
  Wrapper,
  ModalContent,
  ImageContainer,
  Image,
  Overlay,
  HiresImage,
  HiresOverlay,
  Card,
  UL,
  LI,
  Heading,
  Icon,
  Hash,
  Button,
  P,
  HeartIcon,
  ModalSwitch,
  BloodSpan,
  IconChainsaw,
  BannerImg,
  ImageContent,
  UniqueKGFRibbon,
};
