import styled, { keyframes } from "styled-components";
import { Header2 } from "../../styles/Headers";
import pinkHeart from "../../assets/hearts/pink-heart.svg";
import pixelHeart from "../../assets/hearts/pixel.png";

const Fade = require("react-reveal/Fade");

interface ItemType {
  label: string;
  pixel?: boolean;
  suffix?: string;
  prefix?: string;
}

interface RoadmapItemType {
  header: string;
  items: ItemType[];
}

const roadmapItems: RoadmapItemType[] = [
  {
    header: "Art Airdrops & Holder Rewards",
    items: [
      {
        label: "Monthly KGF Stamp art airdrops to all Killer GF holders",
        suffix: "KILLED IT! ONGOING",
      },
      {
        label:
          "KGF Stamps are non-transferable, and can be used to detect past, current, and diamond-handed holders",
      },
      {
        label: "Zeronis Legacy 1/1 Art airdrops for long-term holders",
        suffix: "KILLED IT! ONGOING",
      },
      {
        label: "Other exclusive benefits and airdrops for holders",
        suffix: "EXPLORING",
      },
    ],
  },
  {
    header: "KGF CHARITY Auctions",
    items: [
      {
        label:
          "Monthly collab charity auctions on Foundation with guest artists",
      },
      {
        label: "17+ eth donated from guest artist auctions so far",
        suffix: "KILLED IT!",
      },
      {
        label:
          "Active KGF holders receive a KGF CHARITY stamp gift drawn by the guest artist",
        suffix: "KILLED IT! ONGOING",
      },
    ],
  },
  {
    header: "KILLER MERCH",
    items: [
      {
        label: "High quality merchandise exclusively for Killer GF holders",
      },
      {
        label: "Merch always made available to all KGF Holders all over the world",
      },
      {
        label: "First IRL Airdrop: KGF PILLOWS",
        suffix: "KILLED IT!",
      },
      {
        label: "Hoodies, shirts, and more",
        suffix: "KILLED IT! 1st Drop Complete!",
      },
      {
        label: "Future IRL Airdrops and Merch",
        suffix: "EXPLORING",
      },
    ],
  },
  {
    header: "Metaverse Killer GFs",
    items: [
      {
        label: "WorldwideWebb3 Metaverse integration for every KGF",
        suffix: "LIVE IN WWWB",
      },
      {
        label: "Additional WorldwideWebb3 Integrations",
        suffix: "EXPLORING",
      },
      {
        label: "Integration into other Metaverses",
        suffix: "EXPLORING",
      },
    ],
  },
  {
    header: "KILLER GF GAME",
    items: [
      {
        pixel: true,
        label: "Killer GF: Cyber Run",
        suffix: "KILLED IT! CHECK IT OUT!",
      },
      {
        label: "Lots more to do, but being researched",
        suffix: "EXPLORING",
      },
    ],
  },
  {
    header: "Future Story of Killer GF + ZEROVERSE",
    items: [
      {
        label:
          "Future expansions of the Killer GF Story and World envisioned by Zeronis!",
        suffix: "",
      },
    ],
  },
];

const StyledRoadmap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12rem;
  max-width: 110rem;

  @media only screen and (max-width: 600px) {
    margin: 5rem 0;
    padding: 0 3rem;
  }

  @media only screen and (min-width: 601px) and (max-width: 1200px) {
    margin-top: 5rem;
    padding: 0 3rem;
  }
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 77rem;
`;

const Item = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 3rem;
`;

const Icon = styled.img`
  height: 3.5rem;
  margin-right: 3rem;
  @media only screen and (max-width: 600px) {
    height: 2.7rem;
    margin-right: 1.5rem;
  }
`;

const TextArea = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.h4`
  font-weight: 700;
  color: var(--primary);
  text-transform: uppercase;
  margin-bottom: 0.3rem;

  font-size: 2.4rem;
  @media only screen and (max-width: 600px) {
    font-size: 1.6rem;
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
`;

const ListItem = styled.li`
  font-weight: 500;
  color: var(--main);

  font-size: 2rem;
  margin-top: 0.6rem;
  margin-left: 3rem;
  @media only screen and (max-width: 600px) {
    font-size: 1.5rem;
    margin-top: 0.4rem;
    margin-left: 2rem;
  }
`;

const PinkHighlight = styled.span`
  font-weight: bold;
  color: var(--primary);
  text-transform: uppercase;

  font-size: 2rem;
  @media only screen and (max-width: 600px) {
    font-size: 1.5rem;
  }
`;

const pixelAnimation = keyframes`
  0% {
    filter: brightness(1);
    padding-top: 5px;
  }
  50% {
    filter: brightness(2);
    padding-top: 0px;
  }
  100% {
    filter: brightness(1);
    padding-top: 5px;
  }
`;

const PixelHeart = styled.img`
  height: 2rem;
  margin-left: 1rem;
  animation: ${pixelAnimation} 1.5s linear infinite;
`;

const Roadmap = () => {
  return (
    <StyledRoadmap id="roadmap-scroll">
      <Fade bottom>
        <Header2>roadmap</Header2>
      </Fade>
      <Items>
        {roadmapItems.map((item, index) => (
          <Fade bottom key={index}>
            <Item>
              <Icon src={pinkHeart} />
              <TextArea>
                <Header>{item.header}</Header>
                <List>
                  {item.items.map((item, index) => (
                    <ListItem>
                      {item.prefix && (
                        <PinkHighlight>{item.prefix}</PinkHighlight>
                      )}
                      {item.label}
                      {item.label && item.suffix && <>{" - "}</>}
                      {item.suffix && (
                        <PinkHighlight>{item.suffix}</PinkHighlight>
                      )}
                      {item.pixel && (
                        <PixelHeart src={pixelHeart} alt="Pixel heart" />
                      )}
                    </ListItem>
                  ))}
                </List>
              </TextArea>
            </Item>
          </Fade>
        ))}
      </Items>
    </StyledRoadmap>
  );
};

export default Roadmap;
