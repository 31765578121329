import styled from "styled-components";

import { Header2 } from "../../styles/Headers";
import artist from "../../assets/zeronis/artist.jpg";
import SubHeader from "../../styles/SubHeader";
import ArtistSocials from "./ArtistSocials";

const Fade = require("react-reveal/Fade");

const StyledArtist = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-width: 110rem;
  margin: 12rem 0;
  align-items: center;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    margin: 5rem 0;
    padding: 0 3rem;
  }

  @media only screen and (min-width: 601px) and (max-width: 1200px) {
    margin: 5rem 0;
    padding: 0 3rem;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Image = styled.img`
  width: 29rem;
  margin-left: 5rem;

  @media only screen and (max-width: 600px) {
    margin-left: 0;
    width: 100%;
    margin-top: 3rem;
  }

  @media only screen and (min-width: 601px) and (max-width: 1200px) {
    width: 25rem;
    margin-left: 3rem;
    margin-top: 3rem;
  }
`;

const HideMobile = styled.div`
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const HideDesktop = styled.div`
  @media only screen and (min-width: 601px) {
    display: none;
  }
`;

const Artist = () => {
  return (
    <StyledArtist id="artist-scroll">
      <TextContainer>
        <Fade bottom>
          <Header2>Artist</Header2>
        </Fade>
        <Fade bottom>
          <SubHeader>
            Zeronis is a concept artist, father to three cats, content creator,
            NFT artist, and an avid gamer. A former senior concept artist at
            Riot Games for over 10 years, as well as a designer of many of the
            popular champions including Ahri, Gwen, and Vi. He also designed
            many successful skins such as Star Guardian, K/DA, and more.
          </SubHeader>
          <br />
          <SubHeader>
            He is a prominent NFT artist with a myriad of anime inspired work on
            Foundation and is always looking for ways to share his characters
            and stories in this space. He is striving to create the best Waifu
            Web3 collecting game on the market.
          </SubHeader>
        </Fade>
        <HideMobile>
          <ArtistSocials />
        </HideMobile>
      </TextContainer>
      <Fade bottom>
        <Image src={artist} alt="artist profile pic" />
      </Fade>
      <HideDesktop>
        <ArtistSocials />
      </HideDesktop>
    </StyledArtist>
  );
};

export default Artist;
