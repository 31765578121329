import styled from "styled-components";

import { Header2 } from "../../styles/Headers";
import SubHeader from "../../styles/SubHeader";

import kiwi from "../../assets/team/kiwi.jpg";
import naninna from "../../assets/team/naninna.jpg";
import chase from "../../assets/team/chase.jpg";
import m from "../../assets/team/m.jpg";
import thefatdoinks from "../../assets/team/thefatdoinks.jpg";
import upsidan from "../../assets/team/upsidan.jpg";
import bruja from "../../assets/team/bruja.jpg";

import gitHub from "../../assets/socials/github.svg";
import twitter from "../../assets/socials/twitter.svg";

const Fade = require("react-reveal/Fade");

interface TeamType {
  image: string;
  name: string;
  description?: string;
  twitter?: string;
  gitHub?: string;
}

const teamMembers: TeamType[] = [
  {
    image: kiwi,
    name: "Kiwi - Co-Founder and Developer",
    description:
      "Co-Founder of uwucrew. Designed the Killer GF NFT collection, responsible for leading the team and all technical needs.",
    twitter: "0xKiwi_",
    gitHub: "0xKiwi",
  },
  {
    image: naninna,
    name: "Naninna - Artist",
    description:
      "Another artist on our team who was selected from the KGF community! She is excited to contribute towards the creative future of KGF!",
    twitter: "NaninnaTH",
  },
  {
    image: thefatdoinks,
    name: "FatDoinks - Head of Marketing",
    description:
      "Helps the team strategize and deliver with intention. Brings on Killer ideas for marketing!",
    twitter: "thefatdoinks",
  },
  {
    image: upsidan,
    name: "upsidan - Project Manager",
    description:
      "Kiwi's #1 fan. As project manager he makes sure everyone is doing their job and that we are fulfilling expectations.",
    twitter: "upsidan",
  },
  {
    image: bruja,
    name: "BitchinBruja - Social Media Assistant",
    description:
      "Bruja helps us maintain our presence at IRL events and helps us run our social media.",
    twitter: "bitchin_bruja",
  },
  {
    image: chase,
    name: "Chase Manning - Developer",
    description:
      "Website developer for Killer GF. Working to make sure everything is looking Killer online.",
    twitter: "chase_manning_",
    gitHub: "chase-manning",
  },
  {
    image: m,
    name: "Morello - Advisor",
    description:
      "Essential team member from uwucrew. He assists wherever needed.",
    twitter: "morellostorment",
  },
];

const StyledTeam = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 110rem;
  margin: 12rem 0;

  @media only screen and (max-width: 600px) {
    margin: 5rem 0;
    padding: 0 3rem;
  }

  @media only screen and (min-width: 601px) and (max-width: 1200px) {
    margin: 5rem 0;
    padding: 0 3rem;
  }
`;

const TopSubHeader = styled(SubHeader)`
  margin-bottom: 3rem;

  @media only screen and (max-width: 600px) {
    margin-bottom: 2rem;
  }

  @media only screen and (min-width: 601px) and (max-width: 1200px) {
    margin-bottom: 2.5rem;
  }
`;

const Link = styled.a`
  font-size: 2rem;
  font-weight: 500;
  text-decoration: underline;

  @media only screen and (max-width: 600px) {
    font-size: 1.6rem;
  }

  @media only screen and (min-width: 601px) and (max-width: 1200px) {
    font-size: 1.8rem;
  }
`;

const TeamMembers = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  margin-top: 7rem;

  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    margin-left: 0;
    margin-top: 2.5rem;
    grid-gap: 3rem;
  }

  @media only screen and (min-width: 601px) and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
    margin-left: 0;
    margin-top: 3rem;
    grid-gap: 3rem;
  }
`;

const TeamMember = styled.div`
  display: flex;
  flex-direction: column;
`;

const Image = styled.img`
  width: 100%;
`;

const Name = styled.div`
  font-size: 1.6rem;
  font-weight: 700;
  color: var(--main);
  margin-top: 1.4rem;

  @media only screen and (max-width: 600px) {
    margin-top: 0.8rem;
    font-size: 1.4rem;
  }
`;

const Description = styled.div`
  font-size: 1.35rem;
  font-weight: 500;
  color: var(--sub);
  margin-top: 0.6rem;
  line-height: 1.2;

  @media only screen and (max-width: 600px) {
    margin-top: 0.8rem;
    font-size: 1.4rem;
  }
`;

const Socials = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.8rem;
`;

const Social = styled.img`
  width: 2.3rem;
  margin-right: 0.7rem;

  @media only screen and (max-width: 600px) {
    width: 2.8rem;
    margin-right: 0.7rem;
  }
`;

const SocialLink = styled.a`
  cursor: pointer;
  opacity: 0.6;

  transition: opacity 0.3s;
  :hover {
    opacity: 1;
  }
`;

const Team = () => {
  return (
    <StyledTeam id="team-scroll">
      <Fade bottom>
        <Header2>team</Header2>
      </Fade>
      <Fade bottom>
        <TopSubHeader>
          Killer GF was created in collaboration with uwu Labs, the team behind{" "}
          <Link
            href="https://uwucrew.art"
            target="_blank"
            rel="noopener noreferrer"
          >
            uwucrew
          </Link>{" "}
          - an anime-inspired NFT community with a focus on supporting artists!
        </TopSubHeader>
      </Fade>
      <Fade bottom>
        <SubHeader>
          The Killer GF Team is extremely proud to be building the vision of Killer GF and supporting the community! 
          We are all excited to see Killer GF grow together!
        </SubHeader>
      </Fade>
      <TeamMembers>
        {teamMembers.map((teamMember: TeamType,index:number) => (
          <Fade key={index} bottom>
            <TeamMember>
              <Image src={teamMember.image} alt="Artist other artwork" />
              <Name>{teamMember.name}</Name>
              {teamMember.description && (
                <Description>{teamMember.description}</Description>
              )}
              <Socials>
                {teamMember.twitter && (
                  <SocialLink
                    href={`https://twitter.com/${teamMember.twitter}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Social src={twitter} alt="Twitter Icon" />
                  </SocialLink>
                )}
                {teamMember.gitHub && (
                  <SocialLink
                    href={`https://github.com/${teamMember.gitHub}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Social src={gitHub} alt="GitHub Icon" />
                  </SocialLink>
                )}
              </Socials>
            </TeamMember>
          </Fade>
        ))}
      </TeamMembers>
    </StyledTeam>
  );
};

export default Team;
