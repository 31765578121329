import styled from "styled-components";

const SubHeader = styled.p`
  font-size: 2rem;
  font-weight: 500;

  @media only screen and (max-width: 600px) {
    font-size: 1.6rem;
  }

  @media only screen and (min-width: 601px) and (max-width: 1200px) {
    font-size: 1.8rem;
  }
`;

export default SubHeader;
