import styled from "styled-components";

import SubHeader from "../../styles/SubHeader";
import useGlobals from "../../app/hooks/use-globals";
import Button from "../../components/Button";

import hearts from "../../assets/hearts/love-kills.png";

const Fade = require("react-reveal/Fade");

const StyledHero = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 12rem 0;
  max-width: 110rem;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    margin: 5rem 0;
    padding: 0 3rem;
  }

  @media only screen and (min-width: 601px) and (max-width: 1200px) {
    margin: 5rem 0;
    padding: 0 3rem;
  }
`;

const TopSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 78rem;
  margin-right: 7rem;
  margin-bottom: 6rem;

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 2rem;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 4rem;

  @media only screen and (max-width: 600px) {
    margin-top: 2.4rem;
  }
`;

const HeaderContainer = styled.div`
  margin-bottom: 3rem;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 910px) {
    flex-direction: column-reverse;
  }
`;

const Header = styled.h1`
  font-family: Impact, "Anton", sans-serif;
  color: var(--primary);
  text-transform: uppercase;
  text-align: center;

  font-size: 7rem;
  line-height: 8.5rem;
  @media only screen and (max-width: 600px) {
    font-size: 6rem;
    line-height: 7.5rem;
  }
`;

const HeaderImage = styled.img`
  height: 6rem;
  margin-left: 2rem;

  @media only screen and (max-width: 910px) {
    margin-left: 0;
    margin-bottom: 2rem;
  }
`;

const SubHeaderCentered = styled(SubHeader)`
  text-align: center;
`;

const Hero = () => {
  const globals = useGlobals();

  return (
    <StyledHero>
      <TopSection>
        <TextContainer>
          <Fade bottom>
            <HeaderContainer>
              <Header>welcome to killer gf</Header>
              <HeaderImage src={hearts} alt="decorative hearts" />
            </HeaderContainer>
          </Fade>
          <Fade bottom>
            <SubHeaderCentered>
              {`Killer GF is a ${globals.SIZE.toLocaleString()} generative portrait art collection by a former Riot Games Artist, Zeronis, with over 240 meticulously designed features that contrasts cute and aesthetically appealing girlfriends that also happen to be dangerous assassins.`}
            </SubHeaderCentered>
          </Fade>
          <Fade bottom>
            <ButtonContainer>
              <Button
                primary
                text="View on OpenSea"
                click={() =>
                  (window as any)
                    .open("https://opensea.io/collection/killergf", "_blank")
                    .focus()
                }
              />
            </ButtonContainer>
          </Fade>
        </TextContainer>
      </TopSection>
    </StyledHero>
  );
};

export default Hero;
