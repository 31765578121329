import styled from "styled-components";
import Logo from "./Logo";
import Socials from "./Socials";

const StyledFooter = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6rem 4rem;

  /* ::before {
    content: "";
    background: linear-gradient(to top, var(--bg), rgba(0, 0, 0, 0));
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 300%;
  } */

  @media only screen and (max-width: 600px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 3rem;
    justify-items: center;
    padding: 5rem 3rem;
  }

  @media only screen and (min-width: 601px) and (max-width: 1200px) {
    padding: 3rem 3rem;
  }
`;

const SocialMedia = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 600px) {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <SocialMedia>
        <Socials showHeader />
      </SocialMedia>
    </StyledFooter>
  );
};

export default Footer;
