import { Fragment, useEffect, useState } from "react";
import {
  AccordionSection,
  SearchBlock,
  Container,
  AccordionWrap,
  HeadingBlock,
  Heading,
  Wrap,
  SearchInput,
  Button,
  Title,
  Image,
  Icon,
  FormGroup,
  InputLabel,
  Input,
  Dropdown,
  TopSearchBlock,
  TopSearchContainer,
  IconDown,
  Span,
} from "./style";
import styled from "styled-components";
import { iconSearch, iconBars } from "../../assets";
interface FilterGalleryType {
  id: string;
  name: string;
  image: string;
  checkTitle: Array<any>;
}
interface FilterProps {
  active: any;
}

const Wrapper = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  background: var(--white);
  z-index: 9;
  box-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
  transform: ${(props: FilterProps) =>
    props.active ? "translateX(0)" : "translateX(100%)"};
  transition: transform 0.3s;
  width: 380px;
  height: 100vh;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;
const Overlay = styled.div`
  ${(props: FilterProps) =>
    props.active
      ? `background-color: #ffffffcc;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;`
      : ""};
`;

const FilterSidebar = ({
  filterSideHandle,
  active,
  FilterMembers,
  handleSelectionChange,
  selectedItems,
  setSelectedItems,
}: any) => {
  const [clicked, setClicked] = useState<any | null>(null);
  const [topSearch, setTopSearch] = useState<string>("");
  const [search, setSearch] = useState<{ [id: string]: string }>({});
  const [filteredCheckTitles, setFilteredCheckTitles] = useState<{
    [id: string]: Array<any>;
  }>({});

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    category: string,
    filterOption: string
  ) => {
    setSelectedItems((prevState: any) => {
      const prevStateCopy = { ...prevState };
      if (prevStateCopy[category]) {
        if (e.target.checked) {
          prevStateCopy[category].push(filterOption);
        } else {
          prevStateCopy[category] = prevStateCopy[category].filter(
            (id: string) => id !== filterOption
          );
        }
      } else {
        // Create newArray if the array doesn't exist.
        prevStateCopy[category] = e.target.checked ? [filterOption] : [];
      }
      return prevStateCopy;
    });
  };

  useEffect(() => {
    // call the callback function passed in through props
    handleSelectionChange(selectedItems);
  }, [selectedItems, handleSelectionChange]);

  useEffect(() => {
    // call the callback function passed in through props
    setTopSearch(() => selectedItems?.id);
  }, [selectedItems?.id]);

  // When the FilterMembers or search state updates, update the filteredCheckTitles state
  useEffect(() => {
    const newFilteredCheckTitles: { [id: string]: Array<any> } = {};
    FilterMembers.forEach((filterItem: FilterGalleryType) => {
      let searchText = "";
      if (
        search[filterItem.id] &&
        filterItem.checkTitle &&
        filterItem.checkTitle.length
      ) {
        searchText = search[filterItem.id].toLowerCase();
      }
      newFilteredCheckTitles[filterItem.id] = (
        filterItem.checkTitle || []
      ).filter((title: any) => title.name.toLowerCase().includes(searchText));
    });
    setFilteredCheckTitles(newFilteredCheckTitles);
  }, [FilterMembers, search]);

  const toggle = (index: any) => {
    if (clicked === index) {
      return setClicked(null);
    }

    setClicked(index);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setTopSearch(() => newValue);
    setSelectedItems((prevState: any) => {
      const prevStateCopy = { ...prevState };
      const parsedNewValue = parseInt(newValue);
      prevStateCopy.id = parsedNewValue ? [parsedNewValue] : [];
      return prevStateCopy;
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    setSearch((prevSearch) => ({
      ...prevSearch,
      [id]: e.target.value,
    }));
  };

  return (
    <Fragment>
      <Wrapper active={active}>
        <AccordionSection>
          <HeadingBlock>
            <Heading>
              <span>FILTER </span> <img src={iconBars} alt="bars" />
            </Heading>
            <button onClick={filterSideHandle}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                aria-hidden="true"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </HeadingBlock>
          <Container>
            <TopSearchContainer>
              <TopSearchBlock>
                <img src={iconSearch} alt="" />
                <SearchInput
                  value={topSearch}
                  placeholder="Filter by ID..."
                  type="search"
                  onChange={(e) => handleSearch(e)}
                  style={{
                    color: `${Number(search.length) === 0 ? "" : "red"}`,
                  }}
                />
              </TopSearchBlock>
            </TopSearchContainer>
            {FilterMembers?.map((filterItem: FilterGalleryType, index: any) => {
              const { image, name, id } = filterItem;
              return (
                <AccordionWrap key={id}>
                  <Wrap
                    onClick={() => toggle(id)}
                    // style={{
                    //   backgroundColor: `${clicked === filterItem.id ? "var(--sub)" : ""}`,
                    //   transition: "background-color 0.4s ease-in-out",
                    //   cursor: "pointer",
                    // }}
                  >
                    <Button>
                      <Title>
                        <Image
                          src={image}
                          alt={name}
                          // style={{
                          //   filter: `${clicked === filterItem.id ? "brightness(0) invert(1)" : ""
                          //     }`,
                          // }}
                        />
                        <Span
                        // style={{
                        //   color: `${clicked === filterItem.id ? "white" : ""}`,
                        //   transition: "color 0.4s ease-in-out",
                        // }}
                        >
                          {name.toUpperCase()}
                        </Span>
                      </Title>

                      <Icon
                      // style={{
                      //   color: `${clicked === filterItem.id ? "var(--white)" : "var(--main)"
                      //     }`,
                      // }}
                      >
                        <IconDown>
                          <svg
                            width="12"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 1.5L6 6.5L11 1.5"
                              stroke="#4F4F4F"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </IconDown>

                        {/* {clicked === filterItem.id ? "-" : "+"} */}
                      </Icon>
                    </Button>
                  </Wrap>
                  {clicked === id ? (
                    <Dropdown>
                      <SearchBlock>
                        <img src={iconSearch} alt="" />
                        <SearchInput
                          value={search[id] || ""}
                          placeholder={`Sort by ${name.toLowerCase()}...`}
                          type="search"
                          onChange={(e) => handleChange(e, id)}
                          style={{
                            color: `${
                              Number(search?.length) === 0 ? "" : "red"
                            }`,
                          }}
                        />
                      </SearchBlock>
                      <FormGroup>
                        {(filteredCheckTitles[id] || []).map(
                          (filterOption, index: number) => (
                            <InputLabel key={index}>
                              <Input
                                type="checkbox"
                                checked={
                                  selectedItems &&
                                  selectedItems[name] &&
                                  selectedItems[name].includes(
                                    filterOption.name
                                  )
                                }
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    e,
                                    name,
                                    filterOption.name
                                  )
                                }
                              />
                              <span>
                                {filterOption.name}
                                {` (${filterOption.count})`}
                              </span>
                            </InputLabel>
                          )
                        )}
                      </FormGroup>
                    </Dropdown>
                  ) : null}
                </AccordionWrap>
              );
            })}
          </Container>
        </AccordionSection>
      </Wrapper>
      <Overlay active={active} onClick={filterSideHandle} />
    </Fragment>
  );
};

export default FilterSidebar;
