import styled from "styled-components";
import gf01 from "../../assets/gfs/killer_gf_01.jpg";
import gf02 from "../../assets/gfs/killer_gf_02.jpg";
import gf03 from "../../assets/gfs/killer_gf_03.jpg";
import gf04 from "../../assets/gfs/killer_gf_04.jpg";

const Fade = require("react-reveal/Fade");

const StyledGallery = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 12rem 0;

  @media only screen and (max-width: 601px) {
    margin: 5rem 0;
    padding: 0 3rem;
  }

  @media only screen and (min-width: 601px) and (max-width: 1200px) {
    margin: 5rem 0;
    padding: 0 3rem;
  }
`;

const Images = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 4rem;

  @media only screen and (max-width: 601px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 3rem;
  }

  @media only screen and (min-width: 601px) and (max-width: 1200px) {
    grid-gap: 3rem;
  }
`;

const Image = styled.img`
  width: 44rem;

  @media only screen and (max-width: 601px) {
    width: 100%;
  }

  @media only screen and (min-width: 601px) and (max-width: 1200px) {
    width: 100%;
  }
`;

const Gallery = () => {
  return (
    <StyledGallery id="gallery-scroll">
      <Images>
        <Fade bottom>
          <Image src={gf01} alt="Killer GF NFT Preview" />
        </Fade>
        <Fade bottom>
          <Image src={gf02} alt="Killer GF NFT Preview" />
        </Fade>
        <Fade bottom>
          <Image src={gf03} alt="Killer GF NFT Preview" />
        </Fade>
        <Fade bottom>
          <Image src={gf04} alt="Killer GF NFT Preview" />
        </Fade>
      </Images>
    </StyledGallery>
  );
};

export default Gallery;
