import { useEffect, useState } from "react";
import {
  AccordionSection,
  SearchBlock,
  Wrapper,
  Container,
  AccordionWrap,
  HeadingBlock,
  Heading,
  Wrap,
  SearchInput,
  Button,
  Title,
  Image,
  Icon,
  FormGroup,
  InputLabel,
  Input,
  Dropdown,
  CloseButton,
  TopSearchBlock,
  Span,
  IconDown,
} from "./style";
import { iconSearch, iconBars, iconLogout } from "../../assets";
const Fade = require("react-reveal/Fade");

interface FilterGalleryType {
  id: string;
  name: string;
  image: string;
  checkTitle: Array<any>;
}

const GalleryFilter = ({
  active,
  setActive,
  FilterMembers,
  handleSelectionChange,
  selectedItems,
  setSelectedItems,
}: any) => {
  const [clicked, setClicked] = useState<any | null>(null);
  const [topSearch, setTopSearch] = useState<string>(selectedItems?.id);
  const [search, setSearch] = useState<{ [id: string]: string }>({});
  const [filteredCheckTitles, setFilteredCheckTitles] = useState<{
    [id: string]: Array<any>;
  }>({});

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    category: string,
    filterOption: string
  ) => {
    setSelectedItems((prevState: any) => {
      const prevStateCopy = { ...prevState };
      if (prevStateCopy[category]) {
        if (e.target.checked) {
          prevStateCopy[category].push(filterOption);
        } else {
          prevStateCopy[category] = prevStateCopy[category].filter(
            (id: string) => id !== filterOption
          );
        }
      } else {
        // Create newArray if the array doesn't exist.
        prevStateCopy[category] = e.target.checked ? [filterOption] : [];
      }
      return prevStateCopy;
    });
  };

  useEffect(() => {
    // call the callback function passed in through props
    handleSelectionChange(selectedItems);
  }, [selectedItems, handleSelectionChange]);

  useEffect(() => {
    // call the callback function passed in through props
    setTopSearch(() => selectedItems?.id);
  }, [selectedItems?.id]);

  // When the FilterMembers or search state updates, update the filteredCheckTitles state
  useEffect(() => {
    const newFilteredCheckTitles: { [id: string]: Array<any> } = {};
    FilterMembers.forEach((filterItem: FilterGalleryType) => {
      let searchText = "";
      if (
        search[filterItem.id] &&
        filterItem.checkTitle &&
        filterItem.checkTitle.length
      ) {
        searchText = search[filterItem.id].toLowerCase();
      }
      newFilteredCheckTitles[filterItem.id] = (
        filterItem.checkTitle || []
      ).filter((title: any) => title.name.toLowerCase().includes(searchText));
    });
    setFilteredCheckTitles(newFilteredCheckTitles);
  }, [FilterMembers, search]);

  const toggle = (id: string) => setClicked(clicked === id ? null : id);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setTopSearch(() => newValue);
    setSelectedItems((prevState: any) => {
      const prevStateCopy = { ...prevState };
      const parsedNewValue = parseInt(newValue);
      prevStateCopy.id = parsedNewValue ? [parsedNewValue] : [];
      return prevStateCopy;
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    setSearch((prevSearch) => ({
      ...prevSearch,
      [id]: e.target.value,
    }));
  };

  return (
    <Fade bottom>
      <Wrapper active={active}>
        <AccordionSection>
          <HeadingBlock>
            <Heading active={active}>
              <span>FILTER</span> <img src={iconBars} alt="bars" />
            </Heading>
            <CloseButton active={active} onClick={() => setActive(!active)}>
              <img src={active ? iconBars : iconLogout} alt="icon" />
            </CloseButton>
          </HeadingBlock>
          <Container>
            <TopSearchBlock active={active}>
              <img src={iconSearch} alt="" />
              <SearchInput
                value={topSearch}
                placeholder="Filter by ID..."
                type="search"
                onChange={(e) => handleSearch(e)}
                style={{
                  color: `${search["search"]?.length === 0 ? "" : "red"}`,
                }}
              />
            </TopSearchBlock>
            {FilterMembers?.map((filterItem: FilterGalleryType, index: any) => {
              const { image, name } = filterItem;
              return (
                <AccordionWrap key={filterItem.id}>
                  <Wrap active={active} onClick={() => toggle(filterItem.id)}>
                    <Button>
                      <Title>
                        <Image src={image} alt={name} active={active} />
                        <Span active={active}>{name.toUpperCase()}</Span>
                      </Title>
                      <Icon>
                        <IconDown active={active}>
                          <svg
                            width="12"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 1.5L6 6.5L11 1.5"
                              stroke="#4F4F4F"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </IconDown>
                      </Icon>
                    </Button>
                  </Wrap>
                  {clicked === filterItem.id ? (
                    <Dropdown active={active}>
                      <SearchBlock>
                        <img src={iconSearch} alt="" />
                        <SearchInput
                          value={search[filterItem.id] || ""}
                          placeholder={`Sort by ${name.toLowerCase()}...`}
                          type="search"
                          onChange={(e) => handleChange(e, filterItem.id)}
                          // style={{ color: `${search?.length === 0 ? "" : "red"}` }}
                        />
                      </SearchBlock>
                      <FormGroup>
                        {(filteredCheckTitles[filterItem.id] || []).map(
                          (filterOption, index: number) => (
                            <InputLabel key={index}>
                              <Input
                                type="checkbox"
                                checked={
                                  selectedItems &&
                                  selectedItems[name] &&
                                  selectedItems[name].includes(
                                    filterOption.name
                                  )
                                }
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    e,
                                    name,
                                    filterOption.name
                                  )
                                }
                              />
                              <span>
                                {filterOption.name}
                                {` (${filterOption.count})`}
                              </span>
                            </InputLabel>
                          )
                        )}
                      </FormGroup>
                    </Dropdown>
                  ) : null}
                </AccordionWrap>
              );
            })}
          </Container>
        </AccordionSection>
      </Wrapper>
    </Fade>
  );
};

export default GalleryFilter;
