import styled from "styled-components";
import { Header2 } from "../../styles/Headers";
import other01 from "../../assets/zeronis/07.jpg";
import other02 from "../../assets/zeronis/05.jpg";
import other03 from "../../assets/zeronis/06.jpg";
import other04 from "../../assets/zeronis/04.jpg";
import SubHeader from "../../styles/SubHeader";

const Fade = require("react-reveal/Fade");

const StyledOtherWork = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-width: 110rem;
  margin: 12rem 0;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    margin: 5rem 0;
    padding: 0 3rem;
  }

  @media only screen and (min-width: 601px) and (max-width: 1200px) {
    flex-direction: column;
    margin: 5rem 0;
    padding: 0 3rem;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const TopSubHeader = styled(SubHeader)`
  margin-bottom: 0.8rem;

  @media only screen and (max-width: 600px) {
    margin-bottom: 1rem;
  }

  @media only screen and (min-width: 601px) and (max-width: 1200px) {
    margin-bottom: 0.5rem;
  }
`;

const BottomSubheader = styled(SubHeader)`
  margin-bottom: 1.3rem;
  width: 100%;
  text-align: right;

  @media only screen and (max-width: 600px) {
    margin-bottom: 2rem;
  }

  @media only screen and (min-width: 601px) and (max-width: 1200px) {
    margin-bottom: 2.5rem;
  }
`;

const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem;
  margin-left: 6rem;

  @media only screen and (max-width: 600px) {
    margin-left: 0;
    grid-gap: 1.5rem;
    margin-top: 2.5rem;
  }

  @media only screen and (min-width: 601px) and (max-width: 1200px) {
    margin-left: 0;
    grid-gap: 3rem;
    margin-top: 3rem;
  }
`;

const Image = styled.img`
  width: 22rem;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }

  @media only screen and (min-width: 601px) and (max-width: 1200px) {
    width: 100%;
  }
`;

const OtherWork = () => {
  return (
    <StyledOtherWork>
      <TextContainer>
        <Fade bottom>
          <Header2>Inspiration</Header2>
        </Fade>
        <Fade bottom>
          <TopSubHeader>
            "The inspiration for the project came from the combination of my
            love for creating badass and sexy female characters while also
            bringing a bit of the yandere flair to the mix. The project is also
            a special collaboration with uwu Labs, the team that created uwucrew."
          </TopSubHeader>
        </Fade>
        <Fade bottom>
          <BottomSubheader>- Zeronis, artist of Killer GF</BottomSubheader>
        </Fade>
      </TextContainer>
      <ImageContainer>
        <Fade bottom>
          <Image src={other02} alt="Artist other artwork" />
        </Fade>
        <Fade bottom>
          <Image src={other03} alt="Artist other artwork" />
        </Fade>
        <Fade bottom>
          <Image src={other04} alt="Artist other artwork" />
        </Fade>
        <Fade bottom>
          <Image src={other01} alt="Artist other artwork" />
        </Fade>
      </ImageContainer>
    </StyledOtherWork>
  );
};

export default OtherWork;
