import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    :root {
        --bg: #F9F7F7;
        --main: #181818;
        --sub: #5F5F5F;
        --primary: #FB006E;
        --error: #FF000F;
        --success: #51c549;
        --shadow-color:#00000014;
        --white:#ffffff;
    }

    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-size: 10px;
        color: var(--main);
        font-family: 'Montserrat', sans-serif;
        /* font-family: Impact, sans-serif; */
    }

    button {
        background: none;
        border: none;
        outline: none;
    }
    
    input {
        border: none;
        outline: none;
    }

    a {
        text-decoration: none;
    }
`;
// ThemedGlobalStyledClassProps
const GlobalStyles = (): JSX.Element => {
  return <GlobalStyle />;
};

export default GlobalStyles;
