import styled from "styled-components";

const Wrapper = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  background: var(--white);
  z-index: 1;
  box-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
  transition: transform 0.3s;
  width: 410px;
  height: 100vh;
`;
const AccordionSection = styled.div``;
const Container = styled.div`
  overflow-y: scroll;
  padding-bottom: 2rem;
  height: 100vh;
  &::-webkit-scrollbar {
    width: 0px;
  }
  @media only screen and ((min-width: 1024px)) {
    max-height: calc(100vh - 11.5rem);
  }
`;
const AccordionWrap = styled.div`
  width: 100%;
`;

const HeadingBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.7rem;
  border-bottom: 1px solid #e8e8e8;

  button {
    width: 25px;
    height: 25px;
  }
  input {
    border: none;
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 0;
    margin-top: 3rem;
  }
`;
const Heading = styled.h2`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 2.5rem;
    font-weight: 500;
    font-family: Impact, "Anton", sans-serif;
    color: var(--main);
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-right: 5px;
  }
  button {
    cursor: pointer;
    svg {
      padding-bottom: 2rem;

      display: block;
      width: 2rem;
      height: 2rem;
    }
  }
`;
const Wrap = styled.div`
  color: var(--white);
  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid #e8e8e8;
  border-left: none;
  border-right: none;
  padding: 1.8rem 1.3rem;
`;
const SearchBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 10px 10px;
  border: 1px solid #bdbdbd;
  margin: 10px 5px 15px;
  input {
    padding: 0;
    border: none;
    font-weight: 500;
    font-size: 1.3rem;
    margin: 0;
  }
  img {
    width: 20px;
    height: 20px;
    margin-right: 3px;
  }
`;
const SearchInput = styled.input`
  width: 100%;
  background-color: transparent;
  color: var(--main);
  border: none;
  font-size: 1.2rem;
  line-height: 1.25rem;
  padding: 1.2rem 1rem;
  margin-bottom: 0.2rem;
  border: 1px solid #bdbdbd;
  &:focus {
    border-color: var(--main);
  }
`;
const Button = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  span {
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--main);
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
  }
`;
const Image = styled.img`
  margin-right: 0.5rem;
  width: 2rem;
  /* width: 24px;
  height: auto; */
`;
const FormGroup = styled.div`
  padding-left: 10px;
`;
const Icon = styled.span`
  font-size: 2rem;
  color: var(--main);
  font-weight: 500;
`;
const InputLabel = styled.label`
  display: flex;
  margin-block: 1.4rem;
  cursor: pointer;
  span {
    font-size: 1.2rem;
    margin-left: 8px;
    font-weight: 600;
    color: var(--main);
    text-transform: capitalize;
    letter-spacing: 0.2px;
  }
  small {
    font-size: 1.2rem;
    font-weight: 600;
    margin-left: 5px;
    opacity: 0.4;
  }
`;
const Input = styled.input`
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--form-background);
  margin: 0;
  font: inherit;
  color: currentColor;
  height: 1.7rem;
  width: 1.7rem;
  border: 1px solid #bdbdbd;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  &::before {
    content: "";
    width: 1rem;
    height: 1.1rem;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var();
    background-color: #e62f6e;
  }
  &:checked::before {
    transform: scale(1);
  }
`;

const Dropdown = styled.div`
  width: 100%;
  /* height: 100px; */
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start; */
`;
const TopSearchBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 12px 14px;
  border: 1px solid #bdbdbd;
  /* margin-bottom: 15px; */

  input {
    padding: 0;
    border: none;
    font-weight: 500;
    font-size: 1.3rem;
    margin: 0;
  }
  img {
    width: 20px;
    height: 20px;
    margin-right: 3px;
  }
`;
const TopSearchContainer = styled.div`
  padding: 10px;
`;
const IconDown = styled.div``;
const Span = styled.span`
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--main);
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
`;
export {
  Wrapper,
  AccordionSection,
  Container,
  AccordionWrap,
  HeadingBlock,
  Heading,
  Wrap,
  SearchInput,
  Button,
  Title,
  Image,
  Icon,
  FormGroup,
  InputLabel,
  Input,
  Dropdown,
  SearchBlock,
  TopSearchBlock,
  TopSearchContainer,
  IconDown,
  Span,
};
