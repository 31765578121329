import styled from "styled-components";
import Hamburger from "./Hamburger";

import Logo from "./Logo";
import NavItems from "./NavItems";
import Socials from "./Socials";

const StyledHeader = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4rem;
  height: 80px;

  @media only screen and (max-width: 750px) {
    padding: 1rem 3rem;
  }
`;

const HideMobile = styled.div`
  @media only screen and (max-width: 750px) {
    display: none;
  }
  @media only screen and (min-width: 601px) and (max-width: 1200px) {
    display: none;
  }
`;

const Header = () => {
  return (
    <StyledHeader id="header-scroll">
      <Logo />
      <HideMobile>
        <NavItems />
      </HideMobile>
      <HideMobile>
        <Socials />
      </HideMobile>
      <Hamburger />
    </StyledHeader>
  );
};

export default Header;
