import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyCivxcYJybgRpLDqVpfHbSaHOug5o-6G64",
  authDomain: "killer-gf.firebaseapp.com",
  databaseURL: "https://killer-gf-default-rtdb.firebaseio.com",
  projectId: "killer-gf",
  storageBucket: "killer-gf.appspot.com",
  messagingSenderId: "517212833777",
  appId: "1:517212833777:web:cde8003a924525a5aee650",
  measurementId: "G-3V6X4WN1HP"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);