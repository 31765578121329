import styled from "styled-components";

import artstation from "../../assets/socials/artstation.svg";
import foundation from "../../assets/socials/foundation.svg";
import instagram from "../../assets/socials/instagram.svg";
import twitter from "../../assets/socials/twitter.svg";

const Fade = require("react-reveal/Fade");

const Socials = styled.div`
  display: flex;
  margin-top: 2.8rem;
  align-items: center;
`;

const SocialLink = styled.a`
  opacity: 0.6;
  cursor: pointer;

  transition: opacity 0.3s;
  :hover {
    opacity: 1;
  }
`;

const Social = styled.img`
  height: 3rem;
  margin-right: 2rem;
`;

const Foundation = styled(Social)`
  height: 2.3rem;
`;

const ArtistSocials = () => {
  return (
    <Fade bottom>
      <Socials>
        <SocialLink
          href="https://www.instagram.com/Zeronis/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Social src={instagram} alt="Instagram Icon" />
        </SocialLink>
        <SocialLink
          href="https://twitter.com/zeronisart"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Social src={twitter} alt="Twitter Icon" />
        </SocialLink>
        <SocialLink
          href="https://www.artstation.com/zeronis-pk"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Social src={artstation} alt="Discord Icon" />
        </SocialLink>
        <SocialLink
          href="https://foundation.app/@zeronis"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Foundation src={foundation} alt="Foundation Icon" />
        </SocialLink>
      </Socials>
    </Fade>
  );
};

export default ArtistSocials;
